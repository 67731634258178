<template>
  <span class="friend-icons" data-bs-toggle="tooltip" :title="popoverText">
    <span v-for="friend in viewed_by_friends" :key="friend._id">
      <FriendIcon :friend="friend" :size="10"></FriendIcon>
    </span>
  </span>
</template>


<script>
import { Tooltip } from 'bootstrap'
import {userStore} from "@/userStore";
import FriendIcon from "@/components/friends/icons/FriendIcon.vue";

export default {
  name: "FriendIconGroup",
  components: {FriendIcon},
  props: ['film_id'],
  computed: {
    popoverText() {
      if ( this.viewed_by_friends.length === 0 ) {
        return '';
      }
      let returnText = 'Viewed by ';
      this.viewed_by_friends.forEach((friend, index, array) => {
        if ( index > 0 ) {
          if ( index === (array.length - 1) ) {
            returnText += ' and ';
          } else {
            returnText += ', ';
          }
        }
        //returnText += `<strong>${friend.username}</strong>`;
        returnText += friend.username;
      })
      return returnText;
    }
  },
  data() {
    return {
      userStore,
      publicPath: process.env.BASE_URL,
      viewed_by_friends: []
    }
  },
  created() {
    this.viewed_by_friends = userStore.user.friends.filter((f) => {
      return f.viewed.includes(this.film_id)
    });

    // // test
    // if ( this.viewed_by_friends.length ) {
    //   this.viewed_by_friends.push(this.viewed_by_friends[0]);
    //   this.viewed_by_friends.push(this.viewed_by_friends[0]);
    //   this.viewed_by_friends.push(this.viewed_by_friends[0]);
    //   this.viewed_by_friends.push(this.viewed_by_friends[0]);
    //   this.viewed_by_friends.push(this.viewed_by_friends[0]);
    //   this.viewed_by_friends.push(this.viewed_by_friends[0]);
    //   this.viewed_by_friends.push(this.viewed_by_friends[0]);
    //   this.viewed_by_friends.push(this.viewed_by_friends[0]);
    //   this.viewed_by_friends.push(this.viewed_by_friends[0]);
    // }
  },
  mounted() {
    new Tooltip(document.body, {
      selector: "[data-bs-toggle='tooltip']",
    })
  },
}
</script>


<style scoped>

.friend-icons {

  margin-left: 5px;
  margin-top: -5px;

}

.profile-pic {
}


</style>