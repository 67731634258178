<template>
  <img :height="height" :width="width" :src="img_url" referrerPolicy="no-referrer">
</template>


<script>

export default {
  name: "FriendIcon",
  props: ['friend', 'size'],
  computed: {
    img_url() {
      return this.friend.picture_url ? this.friend.picture_url : `${process.env.BASE_URL}friend-pic-default.svg`;
    },
    height() {
      return this.size? this.size : 10;
    },
    width() {
      return this.height;
    }

  }
}
</script>


<style scoped>
  img {
    margin-bottom: 4px;
    margin-right: 1px;
    margin-left: 1px;
    border-radius: 50%;
  }
</style>