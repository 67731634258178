<template>

  <section class="film-card">

    <span class="language-watermark">
      <LanguageWatermark v-if="getShouldShowFlag" :language_code="film.original_language" :diameter="50" ></LanguageWatermark>
    </span>

    <div class="checkbox-and-title">
      <span class="checkbox">
        <FilmCheckbox :film_id="film._id"></FilmCheckbox>
      </span>
      <FilmLink :film_name="film.name" :film_id="film._id" :single_line="true"></FilmLink>
      <span class="bookmark">
        <FilmBookmark :film_id="film._id"></FilmBookmark>
      </span>
    </div>

    <div class="winAndNomIcons">
      <img v-for="n in film.numNominations()" :key="n" :src="n <= film.numWins()? `${publicPath}win_icon.svg` : `${publicPath}nom_icon.svg`" alt="nom" width="10" height="10">
    </div>

    <div class="friends">
      <FriendIconGroup :film_id="film._id"></FriendIconGroup>
    </div>

<!--    <LanguageIcon v-if="getShouldShowFlag" :language_code="film.original_language" :superscript="true"></LanguageIcon>-->

    <!--    <div>-->
<!--      <img class="poster-image" :src="film.poster_url" >-->
<!--    </div>-->

  </section>

</template>

<script>

import FilmLink from "@/components/FilmLink.vue";
import FilmCheckbox from "@/components/FilmCheckbox.vue";
import { userStore } from "@/userStore";
import {EventBus} from "@/eventBus";
import { languageData } from "@/language-data";
import FriendIconGroup from "@/components/friends/icons/FriendIconGroup.vue";
import FilmBookmark from "@/components/FilmBookmark.vue";
import LanguageWatermark from "@/components/LanguageWatermark.vue";


export default {
  name: "FilmCard",
  components: {
    /* eslint-disable vue/no-unused-components */
    FilmLink, FilmCheckbox, LanguageWatermark, FriendIconGroup, FilmBookmark
  },
  props: ['film'],
  data() {
    return {
      publicPath: process.env.BASE_URL,
      filmViewed: false,
      languageData: languageData,
      viewed_by_friends: []
    }
  },
  created() {
    this.updateFilmViewed();
    EventBus.on('viewed-state-updated', (_film_id) => {
      if (this.film_id === _film_id) {
        this.updateFilmViewed();
      }
    });

    this.viewed_by_friends = userStore.user.friends.filter((f) => {
      return f.viewed.includes(this.film_id)
    });
  },
  computed: {
    getShouldShowFlag() {
      return (this.film.original_language !== 'en');
    },
    flagPath() {
      return `${this.publicPath}flags/${this.languageData.getFlagCode(this.original_language)}.svg`;
    },
    languageName() {
      return this.languageData.getLanguageName(this.original_language);
    }
  },

  methods: {
    updateFilmViewed() {
      this.filmViewed = userStore.isFilmMarkedViewed(this.film_id);
    },

    onViewedStateUpdated() {
      this.updateFilmViewed();
    },
  }
}
</script>

<style scoped>
.film-card {
  border-width: thin;
  padding: 10px 10px 5px 10px;
  font-size: smaller;
}

.checkbox-and-title {
  display: flex;
  margin: 0px;

  & .checkbox {
    margin-top: -1px;
    margin-right: 10px;
  }
  & .bookmark {
    margin-left: 10px;
  }
}

.poster-image {
  float: right;
  width:10%!important;


}

.winAndNomIcons {
  margin-left: 26px;
  font-size: 8px;

  & img {
    margin-right: 1px;
    margin-left: 1px;
  }
}

.friends {
  opacity: 75%;
  margin-left: 21px;
}

.nominations-and-friends {
  display: flex;
  margin: 0px;

  & .winAndNomIcons {
    display: inline-block;
    width: 100%;

    margin-left: 26px;
    font-size: 8px;

    & img {
      margin-right: 1px;
      margin-left: 1px;
    }
  }

  & .friends {
    opacity: 60%;
    margin-right: 10px;
  }
}

.language-watermark {
  float: right;
  margin-right: 80px;
}


</style>