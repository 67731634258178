<template>
  <div v-if="!isHidden" class="card card-body">
    <span>Seen by </span>
    <span v-for="(friend, index) in viewed_by_friends" :key="friend._id">
      <FriendIcon :friend="friend" :size="15"></FriendIcon>
      <span class="friend-name">{{friend.username}}</span>{{friendDelimiter(index)}}
    </span>
  </div>
</template>


<script>
import {userStore} from "@/userStore";
import FriendIcon from "@/components/friends/icons/FriendIcon.vue";
export default {
  name: "ViewedByFriendsCard",
  components: {FriendIcon},
  props: ['film_id'],
  computed: {
    isHidden() {
      // hidden if no friends to display
      return !this.viewed_by_friends.length;
    },
    num_friends_viewed() {
      return this.viewed_by_friends.length;
    },
    viewed_by_friends() {
      if ( !userStore.user?.friends?.length ) {
        return [];
      }
      return userStore.user.friends.filter((f) => {
        return f.viewed.includes(this.film_id)
      });
    },
  },
  methods: {
    friendDelimiter(index) {
      const elementNum = index + 1;
      return (
          ( elementNum === (this.num_friends_viewed - 1) )? ' & '
              : ( elementNum < this.num_friends_viewed )? ', '
                  : ( elementNum === this.num_friends_viewed )? '.'
                      : ''
      );
    }
  }
}
</script>


<style scoped>
  .card-body {
    color: cornflowerblue;
    background-color: azure;
    margin-top: 20px;
    display: inline-block;

    .friend-name {
      font-weight: 500;
    }

  }
</style>