<template>
  <span :class="getIsSuperscript? 'language-icon-superscript' : 'language-icon'" data-bs-toggle="tooltip" :title="languageName">
    <img :height="getHeight" :src="flagPath">
  </span>
</template>


<script>
import { languageData } from "@/language-data";
import { Tooltip } from 'bootstrap'


export default {
  name: "LanguageIcon",
  props: ['language_code', 'height', 'superscript'],
  computed: {

    getHeight() {
      return this.height? this.height : 10;
    },

    getIsSuperscript() {
      return this.superscript === true;
    },

    flagPath() {
      return `${this.publicPath}flags/${this.languageData.getFlagCode(this.language_code)}.svg`;
    },
    languageName() {
      return this.languageData.getLanguageName(this.language_code);
    }
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      languageData: languageData
    }
  },
  mounted() {
    new Tooltip(document.body, {
      selector: "[data-bs-toggle='tooltip']",
    })
  },
}
</script>


<style scoped>

.language-icon {
  //background-color: lightblue;
  //opacity: 50%;

  z-index: 1;
  position: relative;
  border-style: none;
  /*
  padding: 10px 5px 5px 5px;
  margin-left: -8px;
   */

  img {
    border-style: solid;
    border-width: 0.1px 0.1px 0.1px 0.1px;
    border-color: darkgrey;
    margin-top: -2px;
    /*
    margin-top: -4px;
    margin-left: 4px;
     */
  }
}

  .language-icon-superscript {
    //background-color: lightblue;
    //opacity: 50%;

    z-index: 1;
    position: relative;
    border-style: none;
    /*
    padding: 10px 0px 5px 5px;
    margin-left: -7px;
     */

    img {
      border-style: solid;
      border-width: 1px 1px 1px 1px;
      border-color: darkgrey;
      margin-top: -1.5ex;
      /*
      margin-left: 4px;
       */
    }
  }
</style>