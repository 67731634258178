<template>
  <div v-if="isAdmin" class="container">
    <h1 class="text-danger"><i class="fa fa-wrench"></i> Set Category Winners</h1>
    <hr>
    <h3>{{ceremonyHeaderText}}</h3>
    <div class="card text-dark bg-light" v-for="(category) in categoryList" :key="category">
      <div class="card-header">
        <strong>{{category}}</strong>
      </div>
      <div class="card-body">
        <div v-for="(film) in filmsNominatedForCategory(category)" :key="film.name">
          <FilmLink :film_name="film.name" :film_id="film._id" :no_strikethrough="true"></FilmLink>
          <p v-for="nomination in film.nominationsForCategory(category)" :key="nomination._id">
            <NominationWonCheckbox :nomination="nomination"></NominationWonCheckbox>
            <small><i>{{nomination.nominee}}</i></small>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import axios from "axios";
import {Film} from "@/film";
import NominationWonCheckbox from "@/components/admin/NominationWonCheckbox.vue";
import {userStore} from "@/userStore";
import FilmLink from "@/components/FilmLink.vue";

export default {
  name: "SetCategoryWinnersPage",
  components: {FilmLink, NominationWonCheckbox},
  props: ['ceremony_num'],
  data() {
    return {
      filmList: []
    }
  },
  computed: {
    isAdmin() {
      return userStore.isAdmin();
    },
    ceremonyHeaderText() {
      return Film.getCeremonyHeaderText(this.ceremony_num);
    },
    categoryList() {
      return Film.getNominatedCategoriesForFilms(this.filmList);
    }
  },
  async created() {
    return axios.get(`/api/ceremony/${this.ceremony_num}/films`)
        .then((response) => {
          this.filmList = response.data.map(f => new Film(f));
        })
        .catch((err) => {
          console.log(err);
        });
  },
  methods : {
    filmsNominatedForCategory(_category) {
      return Film.filterFilmsNominatedForCategory(this.filmList, _category);
    }
  }
}
</script>


<style scoped>
.card {
  border-color: crimson;
  margin-top: 20px;
  margin-bottom: 20px;
}

</style>