<template>

  <div class="container">
    <div v-if="user">
<!-- Ad for 2024 broadcast of 96th ceremony      -->
<!--      <a href="https://abc.com/shows/oscars" target='blank'>-->
<!--        <img class="banner-image" :src="`${this.publicPath}promos/2024OscarsOnABC.png`">-->
<!--      </a>-->

      <LoadingSpinner v-if="!ceremonyOverview" class="text-secondary" loading_text="loading" style="margin-top: 50px;"></LoadingSpinner>

      <div v-else v-for="(ceremony_info, index) in ceremonyOverview" :key="index">
        <hr v-if="index !== 0">
        <h5 class="text-secondary">
          <a href="/checklist" @click.prevent="navigateToChecklist(ceremony_info.ceremony)">
            {{getCeremonyHeader(ceremony_info)}}
          </a>
        </h5>

        <AchievementGrapic
            v-for="(cat) in ceremony_info.nomination_summary"
            :key="cat.category_name"
            :film_ids="cat.films.map(film_obj => film_obj.film_id)"
            :category_name="cat.category_name"
            :ceremony="ceremony_info.ceremony"
            size="50"
        >
        </AchievementGrapic>

<!--
        <AchievementGrapic
            v-for="(cat) in firstHalfOfArray(ceremony_info.nomination_summary)"
            :key="cat.category_name"
            :film_ids="cat.films.map(film_obj => film_obj.film_id)"
            :ceremony="ceremony_info.ceremony"
            size="15"
        >
        </AchievementGrapic>

        <br>

        <AchievementGrapic
            v-for="(cat) in secondHalfOfArray(ceremony_info.nomination_summary)"
            :key="cat.category_name"
            :film_ids="cat.films.map(film_obj => film_obj.film_id)"
            :ceremony="ceremony_info.ceremony"
            size="15"
        >
        </AchievementGrapic>
-->

      </div>

    </div>
  </div>
</template>

<script>


import axios from "axios";
import AchievementGrapic from "@/components/AchievementGrapic.vue";
import {Film} from "@/film";
import {userStore} from "@/userStore";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

export default {
  name: "DashboardPage",
  components: {
    LoadingSpinner,
    AchievementGrapic
  },
  computed: {
    user() {
      return userStore.user;
    }
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      ceremonyOverview: null
    }
  },
  async created() {
    axios.get('/api/ceremony/overview')
        .then((result) => {
          this.ceremonyOverview = result.data;

          // put most recent ceremonies first
          this.ceremonyOverview.sort((cer1, cer2) => {
            return (cer1.ceremony < cer2.ceremony)? 1 : (cer1.ceremony > cer2.ceremony)? -1 : 0;
          });
          //console.log(this.ceremonyOverview);
        })

        .catch((err) => {
          console.log(err);
        });
  },
  methods: {
    getCeremonyHeader(ceremony_info) {
      const kMonthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      const date = Film.getDateOfCeremony(ceremony_info.ceremony);
      return `${Film.getCeremonyOrdinalText(ceremony_info.ceremony)} Ceremony • ${kMonthNames[date.getUTCMonth()]} ${date.getUTCDate()} ${date.getUTCFullYear()}`;
    },

    firstHalfOfArray(_array) {
      const half = Math.ceil(_array.length / 2)
      return _array.slice(0, half);
    },

    secondHalfOfArray(_array) {
      const half = Math.ceil(_array.length / 2)
      return _array.slice(half);
    },

    navigateToChecklist(_ceremony) {
      this.$router.push({
        name: 'ChecklistPage',
        params: { init_ceremony: _ceremony }
      });
    }

  }
}
</script>


<style scoped>

.banner-image {
  margin-top: -10px;
  padding-bottom: 30px;
  width: 100%;
}

a {
  text-decoration: inherit;
  color: inherit;
  cursor: pointer;
}
a:hover {
  color: var(--bs-link-hover-color);
}
</style>