<template>
  <div class="row checkbox-and-title">

    <div class="col-auto" style="padding-right: 0px;">
      <FilmCheckbox :film_id="film_id"></FilmCheckbox>
    </div>

    <div class="col" style="padding-right: 0px; text-align: left;">
      <FilmLink :film_name="film_name" :film_id="film_id" :film_year="film_year"></FilmLink>
      <LanguageIcon v-if="getShouldShowFlag" :language_code="original_language" :superscript="true"></LanguageIcon>
<!--      <FilmBookmark :film_id="film_id"></FilmBookmark>-->
      <FriendIconGroup :film_id="film_id"></FriendIconGroup>
      <br>
      <div class="winAndNomIcons">
        <img v-for="n in this.num_noms" :key="n" :src="n <= this.num_wins? `${publicPath}win_icon.svg` : `${publicPath}nom_icon.svg`" alt="nom" width="10" height="10">
      </div>
    </div>
  </div>


</template>

<script>

import FilmLink from "@/components/FilmLink.vue";
import FilmCheckbox from "@/components/FilmCheckbox.vue";
import LanguageIcon from "@/components/LanguageIcon.vue";
import { userStore } from "@/userStore";
import {EventBus} from "@/eventBus";
import { languageData } from "@/language-data";
import { Tooltip } from 'bootstrap'
import FriendIconGroup from "@/components/friends/icons/FriendIconGroup.vue";
//import FilmBookmark from "@/components/FilmBookmark.vue";


export default {
  name: "FilmWithCheckbox",
  components: {
    //FilmBookmark,
    FilmLink, FilmCheckbox, LanguageIcon, FriendIconGroup
  },
  props: ['film_id', 'film_name', 'film_year', 'original_language', 'num_noms', 'num_wins'],
  data() {
    return {
      publicPath: process.env.BASE_URL,
      filmViewed: false,
      languageData: languageData,
      viewed_by_friends: []
    }
  },
  created() {
    this.updateFilmViewed();
    EventBus.on('viewed-state-updated', (_film_id) => {
      if (this.film_id === _film_id) {
        this.updateFilmViewed();
      }
    });

    this.viewed_by_friends = userStore.user.friends.filter((f) => {
      return f.viewed.includes(this.film_id)
    });
  },
  mounted() {
    new Tooltip(document.body, {
      selector: "[data-bs-toggle='tooltip']",
    })
  },
  computed: {
    getShouldShowFlag() {
      return (this.original_language !== 'en');
    },
    flagPath() {
      return `${this.publicPath}flags/${this.languageData.getFlagCode(this.original_language)}.svg`;
    },
    languageName() {
      return this.languageData.getLanguageName(this.original_language);
    }
  },

  methods: {
    updateFilmViewed() {
      this.filmViewed = userStore.isFilmMarkedViewed(this.film_id);
    },

    onViewedStateUpdated() {
      this.updateFilmViewed();
    },
  }
}
</script>

<style scoped>
.checkbox-and-title {
  //font-weight: 500;
  padding-bottom: 10px;
  font-size: smaller;
}

.friendIcons {
  font-size: 8px;

  & img {
    margin-right: 1px;
    margin-left: 1px;
  }
}

.winAndNomIcons {
  font-size: 8px;

  & img {
    margin-right: 1px;
    margin-left: 1px;
  }
}

</style>