<template>
  <span
      class="language-watermark"
      :style="`height: ${diameter}px; width: ${diameter}px;`"
      data-bs-toggle="tooltip"
      data-bs-placement="top"
      data-bs-offset="0,-30"
      :title="languageName">
    <img :height="getImgHeight" :src="flagPath">
  </span>
</template>


<script>
import { languageData } from "@/language-data";
import { Tooltip } from 'bootstrap'


export default {
  name: "LanguageWatermark",
  props: ['language_code', 'diameter'],
  computed: {

    getImgHeight() {
      return this.diameter * 0.4;
    },

    flagPath() {
      return `${this.publicPath}flags/${this.languageData.getFlagCode(this.language_code)}.svg`;
    },
    languageName() {
      return this.languageData.getLanguageName(this.language_code);
    }
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      languageData: languageData
    }
  },
  mounted() {
    new Tooltip(document.body, {
      selector: "[data-bs-toggle='tooltip']",
    })
  },
}
</script>


<style scoped>

.language-watermark {

  /*
  background-color: lightgrey;
   */
  z-index: 1;

  opacity: 10%;
  position: absolute;

  display: flex;
  justify-content: center;
  align-items: center;

  rotate: 10deg;

  border-radius: 50%;
  border-style: double;
  border-width: 5px;
  border-color: darkgrey;

  /*
  z-index: 1;
   */

  img {
    border-style: solid;
    border-width: 1px;
    border-color: darkgrey;
  }
}
</style>