<template>
  <FriendCard class="not-logged-in-card" :friend="friend">

  </FriendCard>

  <div class="card card-body call-to-action-card">

    <div class="invite-text">
      <p>sign in or sign up to connect with</p>
      <p style="" class="friend-name">{{friend.username}}</p>
    </div>

    <SignIn/>
    <!--      <div class="d-grid d-sm-flex justify-content-sm-end">-->
    <!--        <button class="btn btn-sm btn-primary me-sm-2" type="button" @click="sendConnectionRequest()">-->
    <!--          <i class="fa fa-user-plus"></i> send-->
    <!--        </button>-->
    <!--      </div>-->

  </div>
</template>


<script>
import FriendCard from "@/components/friends/cards/FriendCard.vue";
import SignIn from "@/components/auth/SignIn.vue";

export default {
  name: 'FriendCard_NotLoggedIn',
  components: {SignIn, FriendCard},
  props: ['friend'],
  methods: {
  }

}
</script>


<style scoped>

.not-logged-in-card {
  color: color-mix(in srgb, cornflowerblue, transparent 20%);
  border-color: color-mix(in srgb, cornflowerblue, transparent 20%);
  background-color: color-mix(in srgb, aliceblue, transparent 20%);
}

  .call-to-action-card {
    color: cornflowerblue;
    background-color: aliceblue;
    border-color: color-mix(in srgb, cornflowerblue, transparent 50%);;
    border-width: 2px;
    margin-right: 20px;
    margin-left: 20px;
    margin-bottom: 20px;

    .invite-text {
      text-align: center;
      margin-bottom: 10px;

      p {
        margin-bottom: 0px;

        &.friend-name {
          font-weight: 500;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }

}
</style>