<template>
  <div class="container-fluid">
    <nav class="navbar">


        <form class="search-form">

          <div class="input-group">
            <input class="form-control" type="text" placeholder="search" v-model="searchText" />

            <div class="btn-group search-button">
              <input class="btn btn-secondary btn-sm" type="submit" :value="`Search ${searchType}`" @click.prevent="onSearch"/>

              <button type="button" class="btn btn-secondary dropdown-toggle btn-sm dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false">
                <span class="visually-hidden">Toggle Dropdown</span>
              </button>
              <ul class="dropdown-menu search-dropdown dropdown-menu-end">
                <li><a class="dropdown-item" href="#" @click.prevent="searchForTitles">Film Titles</a></li>
                <li><a class="dropdown-item" href="#" @click.prevent="searchOverviews">Film Overviews</a></li>
                <li><a class="dropdown-item" href="#" @click.prevent="searchForNominees">Nominees</a></li>
              </ul>
            </div>

          </div>

        </form>


    </nav>

    <hr>

    <div class="container">
      <h2 class="results-header" v-if="resultsHeader.length > 0">{{resultsHeader}}</h2>

      <LoadingSpinner v-if="isSearching" class="text-secondary" loading_text="searching" style="margin-top: 50px;"></LoadingSpinner>

      <FilmList :films="filmlist" :show_years="true" />
    </div>

  </div>
</template>


<script>
import axios from "axios";
import FilmList from "@/components/FilmChecklist.vue";
import {Film} from "@/film";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

export default {
  name: "SearchPage",
  components: {LoadingSpinner, FilmList},
  data() {
    return {
      isSearching: false,
      searchText: "",
      searchType: "Titles",
      resultsHeader: "",
      filmlist: []
    }
  },

  methods: {

    async searchForTitles() {
      this.searchType = 'Titles';
      this.onSearch();
    },

    async searchForNominees() {
      this.searchType = 'Nominees';
      this.onSearch();
    },

    async searchOverviews() {
      this.searchType = 'Overviews';
      this.onSearch();
    },

    async onSearch() {

      this.searchText = this.searchText.trim().toLowerCase();
      if (this.searchText == '') {
        return;
      }
      this.filmlist = [];
      this.resultsHeader = "";
      this.isSearching = true;

      //console.log(`/api/film/search/${this.searchType.toLowerCase()}/${encodeURIComponent(this.searchText)}`);

      axios.get(`/api/film/search/${this.searchType.toLowerCase()}/${encodeURIComponent(this.searchText)}`)
          .then((response) => {
            this.filmlist = response.data.map(f => new Film(f));
            this.resultsHeader = `${this.filmlist.length} film${this.filmlist.length != 1 ? 's' : ''} with ${this.searchType} containing "${this.searchText}"`;
            //console.log(this.resultsHeader)
            Film.sortFilmsByPrioirity(this.filmlist);
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.isSearching = false;
          })
    }
  }

}
</script>


<style scoped>

.search-form {
  margin: auto;
  .btn {
    //margin-left: 10px;
  }
}

.search-button {
  padding-left: 10px;
}

.search-dropdown {
  margin-left: -20px;
}

.results-header {
  padding-bottom: 20px;
}

</style>