<template>
  <div class="card text-bg-light">

    <div :class="`card-body ${showExpanded? 'expanded' : 'not-expanded'}`">
      <!-- Slot is used for the header, generally an image with this provider's logo. -->
      <slot></slot>
      <span v-show="hideViewedFilms" class="some-films-hidden-note" >
        <i class="fa fa-eye-slash"></i> viewed hidden
      </span>

      <div v-if="showExpanded" class="btn btn-sm btn-show-more" type="button" @click="showExpanded = !showExpanded">
        <i :class="showExpanded? 'fa fa-caret-up' : 'fa fa-caret-down'" aria-hidden="true"></i>
        <span v-text="showExpanded? ' show less ' : ' show more '" aria-hidden="true"></span>
      </div>

      <hr>
      <LoadingSpinner v-if="waitingForResponse" class="text-secondary" style="margin-top: 10px;"></LoadingSpinner>

      <div v-else class="row">
        <!-- feature films in the left column -->
        <div class="col-6">
          <ul v-if="featuresToDisplay.length > 0" class="list-unstyled">
            <span class="films-header">feature films</span>
            <li v-for="(displayFilm) in featuresToDisplay" :key="displayFilm._id" class="container film-name">
              <FilmLink :film_name="displayFilm.name" :film_id="displayFilm._id" :show_if_bookmarked="true"></FilmLink>
              <LanguageIcon class="language-icon" v-if="displayFilm.original_language !== 'en'" :language_code="displayFilm.original_language" :height="5" :superscript="true"></LanguageIcon>
            </li>
          </ul>
        </div>

        <!-- docs and shorts in the right column -->
        <div class="col-6">
          <ul v-if="docsToDisplay.length > 0" class="list-unstyled">
            <span class="films-header">documentaries</span>
            <li v-for="(displayFilm) in docsToDisplay" :key="displayFilm._id" class="container film-name">
              <FilmLink :film_name="displayFilm.name" :film_id="displayFilm._id" :show_if_bookmarked="true"></FilmLink>
              <LanguageIcon class="language-icon" v-if="displayFilm.original_language !== 'en'" :language_code="displayFilm.original_language" :height="5" :superscript="true"></LanguageIcon>
            </li>
          </ul>

          <hr v-if="(docsToDisplay.length > 0) && (shortsToDisplay.length > 0)">

          <ul v-if="shortsToDisplay.length > 0" class="list-unstyled">
            <span class="films-header">shorts</span>
            <li v-for="(displayFilm) in shortsToDisplay" :key="displayFilm._id" class="container film-name">
              <FilmLink :film_name="displayFilm.name" :film_id="displayFilm._id" :show_if_bookmarked="true"></FilmLink>
              <LanguageIcon class="language-icon" v-if="displayFilm.original_language !== 'en'" :language_code="displayFilm.original_language" :height="5" :superscript="true"></LanguageIcon>
            </li>
          </ul>
        </div>
      </div>

    </div>

    <div class="card-footer">
      <div class="btn btn-sm btn-show-more" type="button" @click="showExpanded = !showExpanded">
        <i :class="showExpanded? 'fa fa-caret-up' : 'fa fa-caret-down'" aria-hidden="true"></i>
        <span v-text="showExpanded? ' show less ' : ' show more '" aria-hidden="true"></span>
      </div>
    </div>

  </div>
</template>



<script>
import axios from "axios";
import {Film} from "@/film";
// import FilmWithCheckbox from "@/components/FilmWithCheckbox.vue";
import FilmLink from "@/components/FilmLink.vue";
import { userStore } from "@/userStore";
import LanguageIcon from "@/components/LanguageIcon.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

export default {
  name: "ScheduledFilmListCard",
  components: {LoadingSpinner, LanguageIcon, FilmLink},
  props: ['title', 'provider', 'hideViewedFilms'],
  computed: {
    featuresToDisplay() {
      return this.hideViewedFilms? this.films.features.filter(film => !this.userViewedFilmsSnapshot.includes(film._id)) : this.films.features;
    },
    docsToDisplay() {
      return this.hideViewedFilms? this.films.docs.filter(film => !this.userViewedFilmsSnapshot.includes(film._id)) : this.films.docs;
    },
    shortsToDisplay() {
      return this.hideViewedFilms? this.films.shorts.filter(film => !this.userViewedFilmsSnapshot.includes(film._id)) : this.films.shorts;
    },
  },
  watch: {
    hideViewedFilms(newValue) {
      if ( newValue === true ) {
        this.saveUserViewedFilmsSnapshot();
      }
    }
  },
  data() {
    return {
      waitingForResponse: false,
      films: { features: [], docs: [], shorts: [] },
      showExpanded: false,
      expandedIndex: 8,
      userViewedFilmsSnapshot: []
    }
  },
  async created() {

    this.saveUserViewedFilmsSnapshot();

    // clear our display schedule
    this.films =  { features: [], docs: [], shorts: [] };
    this.waitingForResponse = true;

    // get the schedule for the given provider
    axios.get(`/api/viewing-options/streaming/${this.provider}`)
      .then((response) => {
        //console.log(`/api/viewing-options/streaming/${this.provider} => ${response}`);

        let responseCatalog = response?.data?.catalog;
        if ( !responseCatalog ) {
          throw Error(`Invalid response from /api/viewing-options/streaming/${this.provider}`);
        }

        if ( responseCatalog.length === 0 ) {
          return [ null ];
        }

        // get films in the catalog
        const catalogFilmIds = responseCatalog.map( program => program.film_id );

        return axios.post('/api/film',{ film_ids: catalogFilmIds } );
      })

      .then((response) => {
        //console.log(`/api/film => ${response}`);
        const responseFilmList = response.data.map(_film => new Film(_film));

        // STRATEGY 1: List unviewed films at the top, in priority order; then viewed films (also in priority order)
        //
        // // separate the response films into ones we've viewed, and ones we haven't
        // const viewedFilms = [];
        // const unviewedFilms = [];
        // responseFilmList.forEach((responseFilm) => {
        //   const newFilm = new Film(responseFilm);
        //   if ( userStore.isFilmMarkedViewed(newFilm._id) ) {
        //     viewedFilms.push(newFilm);
        //   } else {
        //     unviewedFilms.push(newFilm);
        //   }
        // });
        // // sort both lists by display priority
        // Film.sortFilmsByPrioirity(viewedFilms);
        // Film.sortFilmsByPrioirity(unviewedFilms);
        //
        // // display as a single list, with the unviewed ones first
        // this.films = unviewedFilms.concat(viewedFilms);

        // STRATEGY 2: List all films chronologically (most recent first)
        //
        Film.sortFilmsChronologically(responseFilmList);
        responseFilmList.reverse();

        // bring all the unviewed-but-bookmarked films to the top
        responseFilmList.sort((film1, film2) => {
          if (userStore.isFilmBookmarkedAndUnviewed(film1._id) && !userStore.isFilmBookmarkedAndUnviewed(film2._id)) {
            return -1;
          }
          if (userStore.isFilmBookmarkedAndUnviewed(film2._id) && !userStore.isFilmBookmarkedAndUnviewed(film1._id)) {
            return 1;
          }
          return 0;
        });

        this.films.docs = responseFilmList.filter((film) => {
          return film.nominations.find((nom) => {
            return nom.category.filters.find((filter) => {
              return filter.toLowerCase().includes('documentary');
            });
          });
        });
        this.films.shorts = responseFilmList.filter((film) => {
          return film.nominations.find((nom) => {
            return nom.category.filters.find((filter) => {
              return filter.toLowerCase().includes('short');
            });
          });
        });
        this.films.features = responseFilmList.filter((film) => {
          return !film.nominations.find((nom) => {
            return nom.category.filters.find((filter) => {
              return ( filter.toLowerCase().includes('documentary') || filter.toLowerCase().includes('short') );
            });
          });
        });
      })

      .catch((err) => {
        console.log(err);
      })

      .finally(() => {
        this.waitingForResponse = false;
      });
  },

  methods: {
    saveUserViewedFilmsSnapshot() {
      // We cache the films a user has viewed. If viewed films are hidden, and something else (like a film modal) marks
      //  a film as viewed, we DON'T want the visibility to react--we just want it to show crossed-out.
      if ( userStore.user?.viewed ) {
        this.userViewedFilmsSnapshot = userStore.user?.viewed;
      }
    }
  }
}

</script>


<style scoped>

  h6 {
    padding-top: 10px;
  }

  .card {
    margin-bottom: 20px;
  }

  .card-body {
    font-size: x-small;
    overflow-y: hidden;

    .films-header {
      font-size: small;
      font-weight: 700;
      color: var(--bs-secondary);
    }

    .language-icon {
      padding-left: 0.2em;
      opacity: 80%;
    }

    li {
        line-height: 105%;
        display: block;
        padding-top: 3px;
        padding-bottom: 3px;
        padding-left: 15px;
        padding-right: 0px;
        text-indent: -10px;
    }
  }

  .card-footer {
    background-color: var(--bs-light);
    border-top: none;
  }

  .expanded {
    max-height: auto;
  }

  .not-expanded {
    max-height: 200px;
  }

  .btn-show-more {
    float: right;
    font-size: small;
    color: var(--bs-secondary);
    opacity: 80%;
  }

  .some-films-hidden-note {
    float: right;
    color: var(--bs-secondary);
    opacity: 80%;
    font-size: 9px;
    margin-top: -4px;
  }

</style>