<template>
    <a :class="link_class" @click="show_modal=true">
      {{film_name}}{{film_year_text}}<span v-if="displayAsBookmarked" class="fa fa-bookmark"></span>
    </a>
    <FilmOrCategoryModal v-if="show_modal" :film_name="film_name" :film_id="film_id" @modal-closed="show_modal=false"></FilmOrCategoryModal>
</template>


<script>
import { userStore } from "@/userStore";
import { EventBus } from "@/eventBus";
import FilmOrCategoryModal from "@/components/modal/FilmOrCategoryModal.vue";

export default {
  name: "FilmLink",
  components: {
    FilmOrCategoryModal
  },
  props: ['film_name', 'film_id', 'film_year', 'no_strikethrough', 'single_line', 'show_if_bookmarked'],
  computed: {
    film_year_text() {
      return ( this.film_year? ` (${this.film_year})` : '' );
    },
    link_class() {
      let linkClass = 'film-text';

      if ( this.displayAsViewed ) {
        linkClass += ' viewed-film-text';
      }
      if ( this.single_line ) {
        linkClass += ' single-line';
      }
      return linkClass;
    }
  },
  data() {
    return {
      show_modal: false,
      displayAsViewed: false,
      displayAsBookmarked: false,
      userStore
    }
  },
  created() {
    this.updateFilmViewed();
    this.updateFilmBookmarked();
    EventBus.on('viewed-state-updated', (_film_id) => {
      if (this.film_id === _film_id) {
        this.updateFilmViewed();
      }
    });
    EventBus.on('bookmark-state-updated', (_film_id) => {
      if (this.film_id === _film_id) {
        this.updateFilmBookmarked();
      }
    });

  },
  methods: {
    updateFilmViewed() {
      this.displayAsViewed = !this.no_strikethrough && userStore.isFilmMarkedViewed(this.film_id);
    },
    updateFilmBookmarked() {
      if ( this.show_if_bookmarked ) {
        this.displayAsBookmarked = userStore.isFilmBookmarkedAndUnviewed(this.film_id);
      }
    }
  }
}
</script>

<style scoped>

.film-text {
  text-decoration: inherit;
  color: inherit;
  cursor: pointer;

  &.single-line {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
    width: 100%;
  }

  &.viewed-film-text {
    text-decoration: line-through;
    opacity: 0.5;
    .fa {
      text-decoration: none;
    }
  }

  &:hover {
    color: var(--bs-link-hover-color);
  }
}

.fa-bookmark {
  color: coral;
  font-size: 50%;
  text-indent: 0px;
  padding-left: 0.3em;
  vertical-align: super;
}


</style>