<template>
  <div v-if="user && userAuthInfo" class="container text-center">
    <div class="row">
      <div class="col-12">
        <h1>Settings</h1>
        <hr>
      </div>
    </div>

    <div class="card">
      <div class="card-header">
        Account
      </div>

      <div class="card-body">
        <div class="auth-info row">
          <p>
            <i class="fa fa-envelope-o"></i>
            signed in with email <span class="email">{{userAuthInfo.email}}</span>
          </p>
        </div>
        <div class="auth-info row">
          <p>
            profile name set to <span class="email">{{user.username}}</span>
          </p>
        </div>
        <router-link class="btn btn-sm btn-outline-primary" to="/reset-password">
          <i class="fa fa-envelope-o"></i> <span v-text="userAuthInfo.isEmailAuthed? 'change password' : 'set password'" aria-hidden="true"></span>
        </router-link>
      </div>



    </div>

  </div>
</template>


<script>
import axios from "axios";
import {userStore} from "@/userStore";

export default {
  name: "SettingsPage",
  computed: {
    user() {
      return userStore.user;
    },
    isAdmin() {
      return userStore.isAdmin();
    },
    isTester() {
      return userStore.isTester();
    }
  },
  data() {
    return {
      waitingForResponse: false,
      userAuthInfo: null,
      logoPath: `${process.env.BASE_URL}logos/`
    }
  },
  async created() {
    axios
        .get("/api/user/auth-info")
        .then((response) => {
          console.log(response.data);
          this.userAuthInfo = response.data;

          const kMonthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
          const creationDateObj = new Date(this.userAuthInfo.accountCreatedDate);
          this.userAuthInfo.accountCreatedDate = creationDateObj;
          this.userAuthInfo.accountCreatedDateText = `${kMonthNames[creationDateObj.getMonth()]} ${creationDateObj.getFullYear()}`;
          console.log(response.data);
        }, (error) => {
          console.log(error);
        });
  },
  methods: {
  }
}
</script>


<style scoped>
.auth-info {
  text-align: left;
  font-size: small;

  p {
    margin-bottom: 0px;
  }
  .email {
    font-weight: 500;
  }
  .google-icon {
    height: 20px;
    margin-top: -2px;
  }
}
</style>