<template>
  <FriendCard :friend="friend" :cancel_button_text="'remove friend'" @cancel-button-clicked="show_confirmation_modal=true"> </FriendCard>

  <GenericModal v-if="show_confirmation_modal" @modal-closed="show_confirmation_modal=false">
    <div class="text-center">
      <div>
        <p>Are you sure you want to disconnect your friendship with<br><strong>{{friend.username}}</strong>?</p>
      </div>
      <button class="btn btn-sm btn-primary" type="button" data-bs-dismiss="modal">
        <i class="fa fa-user"></i> keep friend
      </button>
      <button class="btn btn-sm btn-danger me-sm-2" type="button" data-bs-dismiss="modal" @click="removeFriend()">
        <i class="fa fa-user-times"></i> remove friend
      </button>
    </div>
  </GenericModal>
</template>


<script>
import axios from "axios";
import {userStore} from "@/userStore";
import FriendCard from "@/components/friends/cards/FriendCard.vue";
import GenericModal from "@/components/modal/GenericModal.vue";

export default {
  name: 'FriendCard_Friend',
  components: {GenericModal, FriendCard},
  props: ['friend'],
  emits: ['update-friends-list'],
  data() {
    return {
      show_confirmation_modal: false
    }
  },
  methods: {

    cancelAction() {
      console.log(`cancelAction()`)
      this.show_confirmation_modal = false;
    },

    async removeFriend() {
      console.log(`removeFriend()`)

      if ( !this.friend?._id ) {
        return;
      }
      axios.get(`/api/friend/remove-relationship-with/${this.friend?._id}`)
          .then((response) => {
            userStore.user = response.data;
            console.log(`update-friends-list`)
            this.$emit('update-friends-list');
          })
          .catch((err) => {
            console.log(err);
          });
    },
  }
}
</script>


<style scoped>
  button {
    margin-right: 5px;
    margin-left: 5px;
  }
</style>