<template>
  <div class="card text-center border-primary">
    <div class = "row justify-content-center">
      <div class="col-12">
        <div class="card-body">

        <p v-if="createAccount" class="text-primary"><i class="fa fa-envelope-o"></i> sign up with email</p>
        <p v-else class="text-primary"><i class="fa fa-envelope-o"></i> sign in with email</p>

        <form v-if="!user">

          <div class="form-group">
            <input class="form-control" type="text" placeholder="Email Address" v-model="email" />
          </div>

          <div class="form-group">
            <input class="form-control" type="password" placeholder="Password" v-model="password"/>
          </div>

          <div class="d-grid gap-2">
            <input class="btn btn-outline-primary" v-if="createAccount" type="submit" value="Sign Up" @click.prevent="onSignup"/>
            <input class="btn btn-outline-primary" v-else type="submit" value="Sign In" @click.prevent="onLogin" />
          </div>

          <div class="forgot-password-link">
            <router-link class="text-muted" to="/reset-password">Forgot Password?</router-link>
          </div>

        </form>

        <form v-if="user" @submit.prevent="onLogout">
          <input type="submit" value="Logout" />
        </form>
      </div>
    </div>
    </div>
  </div>
</template>


<script>
/* eslint-disable */
import { defineComponent } from 'vue'
import axios from 'axios'
import {userStore} from "@/userStore";

axios.defaults.withCredentials = true
export default defineComponent({
  name: "EmailAndPassword",
  props: ['createAccount'],
  emits: ['user-updated'],
  computed: {
    user() {
      return userStore.user;
    }
  },
  data() {
    return {
      email: "",
      password: ""
    }
  },
  methods: {
    onSignup() {
      axios
          .post("/api/auth/signup/email", {
            email: this.email,
            password: this.password,
          })
          .then((response) => {
            console.log(response);
            this.$emit('user-updated', response.data);
            this.$router.push({name: 'DashboardPage'});
          })
          .catch((error) => {
            console.log("error:");
            console.log(error);
            if (error.response.data) {
              alert(error.response.data);
            }
          });
    },
    onLogin() {
      axios
          .post("/api/auth/login/email", {
            email: this.email,
            password: this.password,
          })
          .then((response) => {
            if (response.status === 200) {
              console.log("response:");
              console.log(response);
              this.$emit('user-updated', response.data);
              this.$router.push({name: 'AuthComplete', query: { strategy: 'email', success: 'true' }});
            }
          }, (error) => {
            console.log("error:");
            console.log(error);
            if (error.response.data) {
              alert(error.response.data);
            }
          })
    },
    onLogout() {
      axios
          .post("/api/auth/logout")
          .then((response) => {
            console.log(response);
            this.$emit('user-updated', null);
            this.$router.push({name: 'WelcomePage'});
          }, (error) => {
            console.log("error:");
            console.log(error);
            if (error.response.data) {
              alert(error.response.data);
            }
          });
    },
  }
});
</script>


<style scoped>

.card {
  margin-top: 30px;
}

.form-control {
  margin-top: 10px;
  margin-bottom: 10px;
}

.forgot-password-link {
  font-size: smaller;
  margin-top: 20px;
}

</style>