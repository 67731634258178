<template>
  <div class="container">

<!--    waiting for info about this user -->
    <LoadingSpinner v-if="!connectionInfo" class="text-secondary" loading_text="loading" style="margin-top: 50px;"></LoadingSpinner>

<!--    error with this user... maybe the link is bad?-->
    <div v-else-if="connectionInfo.error">
      <p>Something went wrong with this invite.</p>
      <p>{{connectionInfo.error}}</p>
    </div>

<!--    i'm not logged in-->
    <div v-else-if="!loggedIn">
      <FriendCard_NotLoggedIn :friend="connectionInfo.userInfo"></FriendCard_NotLoggedIn>
    </div>

<!--    this user is me-->
    <div v-else-if="connectionInfo.custom_status_code === 'USER_IS_SELF'">
      <FriendCard_SendInvite :friend="connectionInfo.userInfo"></FriendCard_SendInvite>
      <h4 style="text-align: center; margin-top: 40px;" >This is how your connection request looks to others.</h4>
    </div>

<!--    this user is already a friend-->
    <div v-else-if="connectionInfo.custom_status_code === 'ALREADY_FRIENDS'">
      <FriendCard_Friend :friend="connectionInfo.userInfo"></FriendCard_Friend>
      <h4 style="text-align: center;" >You're already friends with {{connectionInfo.userInfo.username}}</h4>
    </div>

<!--    this user is is waiting for my confirmation-->
    <div v-else-if="connectionInfo.custom_status_code === 'WAITING_FOR_MY_CONFIRMATION'">
      <FriendCard_InviteReceived :friend="connectionInfo.userInfo"></FriendCard_InviteReceived>
    </div>

<!--    this user is is waiting for their confirmation-->
    <div v-else-if="connectionInfo.custom_status_code === 'WAITING_FOR_THEIR_CONFIRMATION'">
      <FriendCard_InviteSent :friend="connectionInfo.userInfo"></FriendCard_InviteSent>
      <h4 style="text-align: center;" >Invite already sent to {{connectionInfo.userInfo.username}}.</h4>
    </div>

<!--    trivial case: i'm logged in, and this user is a legit stranger -->
    <div v-else>
      <FriendCard_SendInvite :friend="connectionInfo.userInfo"></FriendCard_SendInvite>
    </div>

  </div>
</template>


<script>
import axios from "axios";
import {userStore} from "@/userStore";
import FriendCard_SendInvite from "@/components/friends/cards/FriendCard-SendInvite.vue";
import FriendCard_NotLoggedIn from "@/components/friends/cards/FriendCard-NotLoggedIn.vue";
import FriendCard_Friend from "@/components/friends/cards/FriendCard-Friend.vue";
import FriendCard_InviteReceived from "@/components/friends/cards/FriendCard-InviteReceived.vue";
import FriendCard_InviteSent from "@/components/friends/cards/FriendCard-InviteSent.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
//import FriendCard_Stranger from "@/components/friends/FriendCard-Stranger.vue";

export default {
  name: "FriendConnectPage",
  components: {
    LoadingSpinner,
  //  FriendCard_Stranger,
    FriendCard_InviteSent, FriendCard_InviteReceived, FriendCard_NotLoggedIn, FriendCard_SendInvite, FriendCard_Friend},
  props: ['user_id_to_connect_with'],
  data() {
    return {
      connectionInfo: null
    }
  },

  computed: {
    loggedIn() {
      if ( userStore.user ) {
        return true;
      }
      return false;
    }
  },

  async created() {

    return this.getCurrentUser()
        .then((user) => {
          userStore.user = user; // user may be null if nobody is logged in
          return axios.get(`/api/friend/connection-info/${this.user_id_to_connect_with}`)
        })

        .then((response) => {
          this.connectionInfo = response.data;
          console.log(this.connectionInfo);
        })

        .catch((err) => {
          console.log(err);
          this.connectionInfo = { error: err.toString() };
        });
  },

  methods: {
    async getCurrentUser() {

      if ( userStore.user ) {
        console.log(`getCurrentUser() - already have login`);
        return userStore.user;
      }

      console.log(`getCurrentUser() - checking for login`);
      return axios
          .get("/api/user/profile")
          .then((response) => {
            console.log(`getCurrentUser() - logged in!`);
            return response.data;
          })
          .catch((error) => {
            console.log(error);
            console.log(`getCurrentUser() - Not logged in.`);
            return null;
          });
    },
    async connectWith(_id) {
      if ( !_id ) {
        return;
      }
      axios.get(`/api/friend/invite/${_id}`)
          .then((response) => {
            userStore.user = response.data;
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.$router.push({name: 'FriendsPage'});
          })
      ;
    },

  }

}
</script>


<style scoped>

</style>