<template>
  <div class="view-choice-icon-group">
    <span v-for="prov in streaming_providers" :key="prov.id">
      <span data-toggle="tooltip" :title="prov.name">
        <img :src="prov.logo_path" class="watch-provider-icon">
      </span>
    </span>
    <a v-if="imdb_link" class="watch-provider-icon" :href="imdb_link" title='IMDB' target='blank'>
      <span style="font-size: 11.5px;" class='fa-stack'>
        <i style="color: black;" class='fa fa-stack-2x fa-square'></i>
        <i style="color: #F5C518;" class='fa fa-stack-2x fa-imdb'></i>
        <i style="opacity: 80%;padding-bottom: 9px;bottom: 0px;top: -10;top: -10;padding-left: 16px;" class="fa fa-stack-1x fa-external-link"></i>
      </span>
    </a>
  </div>
</template>


<script>
export default {
  name: "ViewChoiceIcons",
  props: ['streaming_providers', 'imdb_link'],
}
</script>

<style scoped>

.view-choice-icon-group {
  margin-top: 10px;
  padding-left: 0px;
  text-align: right;
}

  .watch-provider-icon {
    width:20px;
    height:20px;
    border-radius: 25%;
    margin-top: 2px;
    margin-bottom: 2px;
    margin-right: 2px;
    margin-left: 2px;
  }

</style>