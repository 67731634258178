<template>
  <div class="loading-spinner">
    <div v-if="true" class="d-flex justify-content-center">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div v-if="loading_text" class="d-flex justify-content-center">
      <small>{{loading_text}}</small>
    </div>
  </div>
</template>


<script>
export default {
  name: "LoadingSpinner",
  props: ['loading_text'],
}
</script>


<style scoped>
.loading-spinner {
  font-family: var(--bs-body-font-family);
}
</style>