<template>
  <span class="btn btn-outline-secondary btn-sm" type="button" @click="toggleViewed()">
    <span v-if="waitingForResponse" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
    <span v-else-if="filmViewed" class="fa fa-check" aria-hidden="true"></span>
  </span>
</template>


<script>
import axios from "axios";
import { userStore } from "@/userStore";
import { EventBus } from "@/eventBus";

export default {
  name: "FilmCheckbox",
  props: ['film_id'],
  data() {
    return {
      filmViewed: false,
      waitingForResponse: false,
      userStore
    }
  },
  created() {
    this.updateFilmViewed();
    EventBus.on('viewed-state-updated', (_film_id) => {
      if (this.film_id === _film_id) {
        this.updateFilmViewed();
      }
    });
  },
  methods: {
    updateFilmViewed() {
      this.filmViewed = userStore.isFilmMarkedViewed(this.film_id);
    },

    async toggleViewed() {

      if ( this.waitingForResponse ) {
        return;
      }

      this.waitingForResponse = true;
      axios.post(`/api/user/viewed/${this.film_id}/${!this.filmViewed}`)
          .then((response) => {
            // If, for some reason, we're trying to mark a film as viewed or unviewed,
            //  that already IS marked that way, we return a 204 (no content).
            if ( response.status === 200 ) {
              userStore.user = response.data;
              this.updateFilmViewed();
              EventBus.emit('viewed-state-updated', this.film_id);
            }
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.waitingForResponse = false;
          })
    }
  }
}
</script>


<style scoped>
.btn {

  margin-bottom: -2px;
  height: 17px !important;
  width: 16px !important;
  .fa-check {
    position: absolute;
    margin-top: -3px;
    margin-left: -7px;
  }
  .spinner-border {
    opacity: 30%;
    position: absolute;
    height: 10px !important;
    width: 10px !important;
    margin-top: -1px;
    margin-left: -5px;
  }
}

.btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
}
</style>