<template>
  <div class="container">
    <h2 class="results-header" v-if="resultsHeader.length > 0">{{resultsHeader}}</h2>

    <LoadingSpinner v-if="isSearching" class="text-secondary" loading_text="searching" style="margin-top: 50px;"></LoadingSpinner>

    <FilmList :films="filmlist" :show_years="true" />
  </div>
</template>


<script>
import axios from "axios";
import FilmList from "@/components/FilmChecklist.vue";
import {Film} from "@/film";
import { searchStore } from "@/userStore";
import {EventBus} from "@/eventBus";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

export default {
  name: "SearchResultsPage",
  components: {LoadingSpinner, FilmList},
  data() {
    return {
      isSearching: true,
      resultsHeader: "",
      filmlist: []
    }
  },

  created() {
    this.doSearch();

    EventBus.on('search-term-updated', (_type, _text) => {
      console.log(`search-term-updated ( "${_type}", "${_text}" )`);
      this.doSearch();
    });
  },

  methods: {
    async doSearch() {

      this.filmlist = [];

      if ( !searchStore.type || searchStore.type === '' || !searchStore.text || searchStore.text === '') {
        this.resultsHeader = `no results`;
        this.isSearching = false;
        return;
      }

      this.resultsHeader = `Searching for ${searchStore.type} containing "${searchStore.text}"`;
      this.isSearching = true;

      axios.get(`/api/film/search/${searchStore.type.toLowerCase()}/${encodeURIComponent(searchStore.text)}`)
          .then((response) => {
            this.filmlist = response.data.map(f => new Film(f));
            this.resultsHeader = `${this.filmlist.length} film${this.filmlist.length != 1 ? 's' : ''} with ${searchStore.type} containing "${searchStore.text}"`;
            //console.log(this.resultsHeader)
            Film.sortFilmsByPrioirity(this.filmlist);
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.isSearching = false;
          })
    }
  }

}
</script>


<style scoped>

.search-form {
  margin: auto;
  .btn {
    //margin-left: 10px;
  }
}

.search-button {
  padding-left: 10px;
}

.search-dropdown {
  margin-left: -20px;
}

.results-header {
  padding-bottom: 20px;
}

</style>