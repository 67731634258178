<template>
  <div class="container">
    <div v-if="user">
      <div class="d-grid gap-2 d-flex justify-content-end">
        <div class="btn btn-outline-secondary btn-sm btn-toggle-viewed" type="button" @click="hideViewedFilms = !hideViewedFilms">
          <span v-if="hideViewedFilms"><i class="fa fa-eye-slash"></i> unhide viewed films</span>
          <span v-else><i class="fa fa-eye-slash"></i> hide viewed films</span>
        </div>
      </div>

      <hr>

      <div class="row">
        <div class="col-xxl-3 col-lg-4 col-md-6">
          <ScheduledFilmListCard provider="tcm" :hideViewedFilms="hideViewedFilms">
            <img :src="logoPathTo('tcm-logo.svg')" alt="Logo" height="20">
          </ScheduledFilmListCard>
        </div>
        <div class="col-xxl-3 col-lg-4 col-md-6">
          <StreamingFilmListCard provider="Netflix" :hideViewedFilms="hideViewedFilms">
            <img :src="logoPathTo('netflix-logo.svg')" alt="Logo" height="20">
          </StreamingFilmListCard>
        </div>
        <div class="col-xxl-3 col-lg-4 col-md-6">
          <StreamingFilmListCard provider="Max" :hideViewedFilms="hideViewedFilms">
            <img :src="logoPathTo('max-logo.svg')" alt="Logo" height="20">
          </StreamingFilmListCard>
        </div>
        <div class="col-xxl-3 col-lg-4 col-md-6">
          <StreamingFilmListCard provider="Hulu" :hideViewedFilms="hideViewedFilms">
            <img :src="logoPathTo('hulu-logo.svg')" alt="Logo" height="20">
          </StreamingFilmListCard>
        </div>
        <div class="col-xxl-3 col-lg-4 col-md-6">
          <StreamingFilmListCard provider="Peacock Premium" :hideViewedFilms="hideViewedFilms">
            <img :src="logoPathTo('peacock-logo.svg')" alt="Logo" height="20">
          </StreamingFilmListCard>
        </div>
        <div class="col-xxl-3 col-lg-4 col-md-6">
          <StreamingFilmListCard provider="Disney Plus" :hideViewedFilms="hideViewedFilms">
            <img :src="logoPathTo('disney-plus-logo.svg')" alt="Logo" height="20">
          </StreamingFilmListCard>
        </div>
        <div class="col-xxl-3 col-lg-4 col-md-6">
          <StreamingFilmListCard provider="Paramount Plus" :hideViewedFilms="hideViewedFilms">
            <img :src="logoPathTo('paramount-plus-logo.svg')" alt="Logo" height="20">
          </StreamingFilmListCard>
        </div>
        <div class="col-xxl-3 col-lg-4 col-md-6">
          <StreamingFilmListCard provider="Apple TV Plus" :hideViewedFilms="hideViewedFilms">
            <img :src="logoPathTo('apple-tv-plus-logo.svg')" alt="Logo" height="20">
          </StreamingFilmListCard>
        </div>
        <div class="col-xxl-3 col-lg-4 col-md-6">
          <StreamingFilmListCard provider="Amazon Prime Video" :hideViewedFilms="hideViewedFilms">
            <img :src="logoPathTo('amazon-prime-video-logo.svg')" alt="Logo" height="20">
          </StreamingFilmListCard>
        </div>
        <div class="col-xxl-3 col-lg-4 col-md-6">
          <StreamingFilmListCard provider="Kanopy" :hideViewedFilms="hideViewedFilms">
            <img :src="logoPathTo('kanopy-logo.svg')" alt="Logo" height="20">
<!--            <span style="float: right; font-size: 9px; margin-top: -4px;" >-->
<!--              <a class="text-secondary" style="color: inherit; text-decoration: inherit; text-align: right;" href="https://www.kanopy.com/en/signup/find/publiclibrary" title='kanopy' target='blank'>-->
<!--                Free access to thousands of<br>movies with your library card-->
<!--                <sup><i style="" class='fa fa-external-link'></i></sup>-->
<!--              </a>-->
<!--            </span>-->
          </StreamingFilmListCard>
        </div>
      </div>
<!--      <FilmListCard title="viewed films" :film_ids="user.viewed"/>-->
    </div>
  </div>
</template>

<script>

import ScheduledFilmListCard from "@/components/ScheduledFilmListCard.vue";
import StreamingFilmListCard from "@/components/StreamingFilmListCard.vue";
import {userStore} from "@/userStore";

export default {
  name: "DashboardPage",
  components: {
    ScheduledFilmListCard,
    StreamingFilmListCard
  },
  computed: {
    user() {
      return userStore.user;
    }
  },
  methods: {
    logoPathTo(_image) {
      return `${process.env.BASE_URL}logos/content-providers/large-logos/${_image}`;
    }
  },
  data() {
    return {
      hideViewedFilms: false
    }
  },
}
</script>


<style scoped>
  .options-card {
    margin-bottom: 20px;
  }

  .form-check-label {
    font-size: small;
  }

  .btn-toggle-viewed {

  }

  .btn-toggle-viewed:hover {
    color: var(--bs-btn-color);
    background-color: var(--bs-btn-bg);
  }
</style>