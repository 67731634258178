<template>
  <h1>{{this.$route.params.year}}</h1>
  <FilmChecklist :films="filmlist" />
</template>


<script>
import axios from "axios";
import FilmChecklist from "@/components/FilmChecklist.vue";

export default {
  name: "AdminFilms",
  components: {FilmChecklist},
  data() {
    return {
      filmlist: {},
    }
  },
  async created() {
    console.log('created()');
    const response = await axios.get(`/api/admin/films/${this.$route.params.year}`);
    this.filmlist = response.data;
  }
}
</script>


<style scoped>

</style>