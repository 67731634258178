<template>
  <FriendCard :friend="friend"> </FriendCard>
</template>


<script>
import FriendCard from "@/components/friends/cards/FriendCard.vue";

export default {
  name: 'FriendCard_Stranger',
  components: {FriendCard},
  props: ['friend'],
  emits: ['update-friends-list'],
  methods: {
  }

}
</script>


<style scoped>

</style>