<template>
  <div class="btn btn-outline-secondary btn-sm" type="button" @click="toggleWon()">
    <span v-if="waitingForResponse" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
    <img v-else-if="nominationWon" class="checkbox-image checkbox-won" :src="winIcon" alt="won" width="16" height="16">
    <img v-else class="checkbox-image" :src="nomIcon" alt="nom" width="16" height="16">
  </div>
</template>


<script>
import axios from "axios";
import { EventBus } from "@/eventBus";

export default {
  name: "NominationWonCheckbox",
  props: ['nomination'],
  data() {
    return {
      nominationWon: false,
      waitingForResponse: false
    }
  },
  computed: {
    nomIcon() {
      return `${process.env.BASE_URL}nom_icon-bw.svg`
    },
    winIcon() {
      return `${process.env.BASE_URL}win_icon-bw.svg`
    }
  },
  created() {
    this.nominationWon = this.nomination.won;
    // make sure we update our 'nominationWon' property when the updated event is received
    EventBus.on('nomination-won-updated', (_nomination_id, _nomination_won) => {
      if (this.nomination._id === _nomination_id) {
        this.nominationWon = _nomination_won;
      }
    });
  },
  methods: {

    async toggleWon() {

      if ( this.waitingForResponse ) {
        return;
      }


      this.waitingForResponse = true;
      axios.post(`/api/admin/nomination/${this.nomination._id}/set-won/${!this.nominationWon}`)
          .then((response) => {
            //console.log(response);
            const updatedNomination = response.data;
            EventBus.emit('nomination-won-updated', updatedNomination._id, updatedNomination.won);
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.waitingForResponse = false;
          })
    }
  }
}
</script>


<style scoped>
.btn {

  //border-color: lightcoral;

  margin-right:5px;

  height: 20px !important;
  width: 20px !important;

  .checkbox-image {
    position: absolute;
    margin-top: -2.5px;
    margin-left: -8px;
    opacity: 50%;
    &.checkbox-won {
      opacity: 100%;
    }
  }
  .spinner-border {
    opacity: 30%;
    position: absolute;
    height: 12px !important;
    width: 12px !important;
    margin-top: -0.5px;
    margin-left: -6px;
  }
}

.btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
}
</style>