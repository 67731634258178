export let ceremonies = [
  {
    ceremony: 1,
    date: "1929-05-16",
    honoring_years: [1927, 1928],
    venue: "Blossom Room of the Hollywood Roosevelt Hotel"
  },
  {
    ceremony: 2,
    date: "1930-04-03",
    honoring_years: [1928, 1929],
    venue: "Cocoanut Grove of the Ambassador Hotel"
  },
  {
    ceremony: 3,
    date: "1930-11-05",
    honoring_years: [1929, 1930],
    venue: "Fiesta Room of the Ambassador Hotel"
  },
  {
    ceremony: 4,
    date: "1931-11-10",
    honoring_years: [1930, 1931],
    venue: "Sala D'Oro in the Biltmore Hotel"
  },
  {
    ceremony: 5,
    date: "1932-11-18",
    honoring_years: [1931, 1932],
    venue: "Fiesta Room of the Ambassador Hotel"
  },
  {
    ceremony: 6,
    date: "1934-03-16",
    honoring_years: [1932, 1933],
    venue: "Fiesta Room of the Ambassador Hotel"
  },
  {
    ceremony: 7,
    date: "1935-02-27",
    honoring_years: [1934],
    venue: "Biltmore Bowl of the Biltmore Hotel"
  },
  {
    ceremony: 8,
    date: "1936-03-05",
    honoring_years: [1935],
    venue: "Biltmore Bowl of the Biltmore Hotel"
  },
  {
    ceremony: 9,
    date: "1937-03-04",
    honoring_years: [1936],
    venue: "Biltmore Bowl of the Biltmore Hotel"
  },
  {
    ceremony: 10,
    date: "1938-03-10",
    honoring_years: [1937],
    venue: "Biltmore Bowl of the Biltmore Hotel"
  },
  {
    ceremony: 11,
    date: "1939-02-23",
    honoring_years: [1938],
    venue: "Biltmore Bowl of the Biltmore Hotel"
  },
  {
    ceremony: 12,
    date: "1940-02-29",
    honoring_years: [1939],
    venue: "Coconut Grove of the Ambassador Hotel"
  },
  {
    ceremony: 13,
    date: "1941-02-27",
    honoring_years: [1940],
    venue: "Biltmore Bowl of the Biltmore Hotel"
  },
  {
    ceremony: 14,
    date: "1942-02-26",
    honoring_years: [1941],
    venue: "Biltmore Bowl of the Biltmore Hotel"
  },
  {
    ceremony: 15,
    date: "1943-03-04",
    honoring_years: [1942],
    venue: "Cocoanut Grove of the Ambassador Hotel"
  },
  {
    ceremony: 16,
    date: "1944-03-02",
    honoring_years: [1943],
    venue: "Grauman's Chinese Theatre"
  },
  {
    ceremony: 17,
    date: "1945-03-15",
    honoring_years: [1944],
    venue: "Grauman's Chinese Theatre"
  },
  {
    ceremony: 18,
    date: "1946-03-07",
    honoring_years: [1945],
    venue: "Grauman's Chinese Theatre"
  },
  {
    ceremony: 19,
    date: "1947-03-13",
    honoring_years: [1946],
    venue: "Shrine Civic Auditorium"
  },
  {
    ceremony: 20,
    date: "1948-03-20",
    honoring_years: [1947],
    venue: "Shrine Civic Auditorium"
  },
  {
    ceremony: 21,
    date: "1949-03-24",
    honoring_years: [1948],
    venue: "Academy Award Theater"
  },
  {
    ceremony: 22,
    date: "1950-03-23",
    honoring_years: [1949],
    venue: "RKO Pantages Theatre"
  },
  {
    ceremony: 23,
    date: "1951-03-29",
    honoring_years: [1950],
    venue: "RKO Pantages Theatre"
  },
  {
    ceremony: 24,
    date: "1952-03-20",
    honoring_years: [1951],
    venue: "RKO Pantages Theatre"
  },
  {
    ceremony: 25,
    date: "1953-03-19",
    honoring_years: [1952],
    venue: "RKO Pantages Theatre"
  },
  {
    ceremony: 26,
    date: "1954-03-25",
    honoring_years: [1953],
    venue: "RKO Pantages Theatre"
  },
  {
    ceremony: 27,
    date: "1955-03-30",
    honoring_years: [1954],
    venue: "RKO Pantages Theatre"
  },
  {
    ceremony: 28,
    date: "1956-03-21",
    honoring_years: [1955],
    venue: "RKO Pantages Theatre"
  },
  {
    ceremony: 29,
    date: "1957-03-27",
    honoring_years: [1956],
    venue: "RKO Pantages Theatre"
  },
  {
    ceremony: 30,
    date: "1958-03-26",
    honoring_years: [1957],
    venue: "RKO Pantages Theatre"
  },
  {
    ceremony: 31,
    date: "1959-04-06",
    honoring_years: [1958],
    venue: "RKO Pantages Theatre"
  },
  {
    ceremony: 32,
    date: "1960-04-04",
    honoring_years: [1959],
    venue: "RKO Pantages Theatre"
  },
  {
    ceremony: 33,
    date: "1961-04-17",
    honoring_years: [1960],
    venue: "Santa Monica Civic Auditorium"
  },
  {
    ceremony: 34,
    date: "1962-04-09",
    honoring_years: [1961],
    venue: "Santa Monica Civic Auditorium"
  },
  {
    ceremony: 35,
    date: "1963-04-08",
    honoring_years: [1962],
    venue: "Santa Monica Civic Auditorium"
  },
  {
    ceremony: 36,
    date: "1964-04-13",
    honoring_years: [1963],
    venue: "Santa Monica Civic Auditorium"
  },
  {
    ceremony: 37,
    date: "1965-04-05",
    honoring_years: [1964],
    venue: "Santa Monica Civic Auditorium"
  },
  {
    ceremony: 38,
    date: "1966-04-18",
    honoring_years: [1965],
    venue: "Santa Monica Civic Auditorium"
  },
  {
    ceremony: 39,
    date: "1967-04-10",
    honoring_years: [1966],
    venue: "Santa Monica Civic Auditorium"
  },
  {
    ceremony: 40,
    date: "1968-04-10",
    honoring_years: [1967],
    venue: "Santa Monica Civic Auditorium"
  },
  {
    ceremony: 41,
    date: "1969-04-14",
    honoring_years: [1968],
    venue: "Dorothy Chandler Pavilion"
  },
  {
    ceremony: 42,
    date: "1970-04-07",
    honoring_years: [1969],
    venue: "Dorothy Chandler Pavilion"
  },
  {
    ceremony: 43,
    date: "1971-04-15",
    honoring_years: [1970],
    venue: "Dorothy Chandler Pavilion"
  },
  {
    ceremony: 44,
    date: "1972-04-10",
    honoring_years: [1971],
    venue: "Dorothy Chandler Pavilion"
  },
  {
    ceremony: 45,
    date: "1973-03-27",
    honoring_years: [1972],
    venue: "Dorothy Chandler Pavilion"
  },
  {
    ceremony: 46,
    date: "1974-04-02",
    honoring_years: [1973],
    venue: "Dorothy Chandler Pavilion"
  },
  {
    ceremony: 47,
    date: "1975-04-08",
    honoring_years: [1974],
    venue: "Dorothy Chandler Pavilion"
  },
  {
    ceremony: 48,
    date: "1976-03-29",
    honoring_years: [1975],
    venue: "Dorothy Chandler Pavilion"
  },
  {
    ceremony: 49,
    date: "1977-03-28",
    honoring_years: [1976],
    venue: "Dorothy Chandler Pavilion"
  },
  {
    ceremony: 50,
    date: "1978-04-03",
    honoring_years: [1977],
    venue: "Dorothy Chandler Pavilion"
  },
  {
    ceremony: 51,
    date: "1979-04-09",
    honoring_years: [1978],
    venue: "Dorothy Chandler Pavilion"
  },
  {
    ceremony: 52,
    date: "1980-04-14",
    honoring_years: [1979],
    venue: "Dorothy Chandler Pavilion"
  },
  {
    ceremony: 53,
    date: "1981-03-31",
    honoring_years: [1980],
    venue: "Dorothy Chandler Pavilion"
  },
  {
    ceremony: 54,
    date: "1982-03-29",
    honoring_years: [1981],
    venue: "Dorothy Chandler Pavilion"
  },
  {
    ceremony: 55,
    date: "1983-04-11",
    honoring_years: [1982],
    venue: "Dorothy Chandler Pavilion"
  },
  {
    ceremony: 56,
    date: "1984-04-09",
    honoring_years: [1983],
    venue: "Dorothy Chandler Pavilion"
  },
  {
    ceremony: 57,
    date: "1985-03-25",
    honoring_years: [1984],
    venue: "Dorothy Chandler Pavilion"
  },
  {
    ceremony: 58,
    date: "1986-03-24",
    honoring_years: [1985],
    venue: "Dorothy Chandler Pavilion"
  },
  {
    ceremony: 59,
    date: "1987-03-30",
    honoring_years: [1986],
    venue: "Dorothy Chandler Pavilion"
  },
  {
    ceremony: 60,
    date: "1988-04-11",
    honoring_years: [1987],
    venue: "Shrine Civic Auditorium"
  },
  {
    ceremony: 61,
    date: "1989-03-29",
    honoring_years: [1988],
    venue: "Shrine Civic Auditorium"
  },
  {
    ceremony: 62,
    date: "1990-03-26",
    honoring_years: [1989],
    venue: "Dorothy Chandler Pavilion"
  },
  {
    ceremony: 63,
    date: "1991-03-25",
    honoring_years: [1990],
    venue: "Shrine Civic Auditorium"
  },
  {
    ceremony: 64,
    date: "1992-03-30",
    honoring_years: [1991],
    venue: "Dorothy Chandler Pavilion"
  },
  {
    ceremony: 65,
    date: "1993-03-29",
    honoring_years: [1992],
    venue: "Dorothy Chandler Pavilion"
  },
  {
    ceremony: 66,
    date: "1994-03-21",
    honoring_years: [1993],
    venue: "Dorothy Chandler Pavilion"
  },
  {
    ceremony: 67,
    date: "1995-03-27",
    honoring_years: [1994],
    venue: "Shrine Auditorium & Expo Center"
  },
  {
    ceremony: 68,
    date: "1996-03-25",
    honoring_years: [1995],
    venue: "Dorothy Chandler Pavilion"
  },
  {
    ceremony: 69,
    date: "1997-03-24",
    honoring_years: [1996],
    venue: "Shrine Auditorium & Expo Center"
  },
  {
    ceremony: 70,
    date: "1998-03-23",
    honoring_years: [1997],
    venue: "Shrine Auditorium & Expo Center"
  },
  {
    ceremony: 71,
    date: "1999-03-21",
    honoring_years: [1998],
    venue: "Dorothy Chandler Pavilion"
  },
  {
    ceremony: 72,
    date: "2000-03-26",
    honoring_years: [1999],
    venue: "Shrine Auditorium & Expo Center"
  },
  {
    ceremony: 73,
    date: "2001-03-25",
    honoring_years: [2000],
    venue: "Shrine Auditorium & Expo Center"
  },
  {
    ceremony: 74,
    date: "2002-03-24",
    honoring_years: [2001],
    venue: "Kodak Theatre at Hollywood & Highland Center"
  },
  {
    ceremony: 75,
    date: "2003-03-23",
    honoring_years: [2002],
    venue: "Kodak Theatre at Hollywood & Highland Center"
  },
  {
    ceremony: 76,
    date: "2004-02-29",
    honoring_years: [2003],
    venue: "Kodak Theatre at Hollywood & Highland Center"
  },
  {
    ceremony: 77,
    date: "2005-02-27",
    honoring_years: [2004],
    venue: "Kodak Theatre at Hollywood & Highland Center"
  },
  {
    ceremony: 78,
    date: "2006-03-05",
    honoring_years: [2005],
    venue: "Kodak Theatre at Hollywood & Highland Center"
  },
  {
    ceremony: 79,
    date: "2007-02-25",
    honoring_years: [2006],
    venue: "Kodak Theatre at Hollywood & Highland Center"
  },
  {
    ceremony: 80,
    date: "2008-02-24",
    honoring_years: [2007],
    venue: "Kodak Theatre at Hollywood & Highland Center"
  },
  {
    ceremony: 81,
    date: "2009-02-22",
    honoring_years: [2008],
    venue: "Kodak Theatre at Hollywood & Highland Center"
  },
  {
    ceremony: 82,
    date: "2010-03-07",
    honoring_years: [2009],
    venue: "Kodak Theatre at Hollywood & Highland Center"
  },
  {
    ceremony: 83,
    date: "2011-02-27",
    honoring_years: [2010],
    venue: "Kodak Theatre at Hollywood & Highland Center"
  },
  {
    ceremony: 84,
    date: "2012-02-26",
    honoring_years: [2011],
    venue: "Hollywood & Highland Center"
  },
  {
    ceremony: 85,
    date: "2013-02-24",
    honoring_years: [2012],
    venue: "Dolby Theatre at the Hollywood & Highland Center"
  },
  {
    ceremony: 86,
    date: "2014-03-02",
    honoring_years: [2013],
    venue: "Dolby Theatre at the Hollywood & Highland Center"
  },
  {
    ceremony: 87,
    date: "2015-02-22",
    honoring_years: [2014],
    venue: "Dolby Theatre at the Hollywood & Highland Center"
  },
  {
    ceremony: 88,
    date: "2016-02-28",
    honoring_years: [2015],
    venue: "Dolby Theatre at the Hollywood & Highland Center"
  },
  {
    ceremony: 89,
    date: "2017-02-26",
    honoring_years: [2016],
    venue: "Dolby Theatre at the Hollywood & Highland Center"
  },
  {
    ceremony: 90,
    date: "2018-03-04",
    honoring_years: [2017],
    venue: "Dolby Theatre at the Hollywood & Highland Center"
  },
  {
    ceremony: 91,
    date: "2019-02-24",
    honoring_years: [2018],
    venue: "Dolby Theatre at the Hollywood & Highland Center"
  },
  {
    ceremony: 92,
    date: "2020-02-09",
    honoring_years: [2019],
    venue: "Dolby Theatre at the Hollywood & Highland Center"
  },
  {
    ceremony: 93,
    date: "2021-04-25",
    honoring_years: [2020],
    venue: "Union Station Los Angeles and the Dolby Theatre at the Hollywood & Highland Center"
  },
  {
    ceremony: 94,
    date: "2022-03-27",
    honoring_years: [2021],
    venue: "Dolby Theatre at Ovation Hollywood"
  },
  {
    ceremony: 95,
    date: "2023-03-12",
    honoring_years: [2022],
    venue: "Dolby Theatre at Ovation Hollywood"
  },
  {
    ceremony: 96,
    date: "2024-03-10",
    honoring_years: [2023],
    venue: "Dolby Theatre at Ovation Hollywood"
  },
  {
    ceremony: 97,
    date: "2025-03-02",
    honoring_years: [2024],
    venue: ""
  },
];