<template>


  <div v-if="user" class="container">
    <!--    header-->
    <div class="card card-body">

      <div class="d-flex">
        <div class="p-2 w-100">
          <span class="display-5">
            <img v-if="user.picture_url" :src="user.picture_url" height="50" class="profile-pic" referrerPolicy="no-referrer">
            <i v-else class="fa fa-user-circle"></i> {{user.username}}
          </span>
        </div>
        <div class="p-2 flex-shrink-1">
          <a class="edit-profile-name" href="#" title="edit profile" @click.prevent="showUpdateUsernameModal">
            <i class="fa fa-pencil-square-o"></i>
          </a>
        </div>
      </div>

      <!--    auth info-->
      <div v-if="userAuthInfo" class="auth-info text-muted row">
        <p v-if="userAuthInfo.isGoogleAuthed">
          <img class="google-icon" :src="`${logoPath}google-logo.svg`"/>
          signed in with account <span class="email">{{userAuthInfo.email}}</span>
        </p>
        <p v-if="userAuthInfo.isEmailAuthed">
          <i class="fa fa-envelope-o"></i>
          signed in with email <span class="email">{{userAuthInfo.email}}</span>
        </p>
      </div>
    </div>
    <div class="profile-body">
      <div>
        <span style="margin-right:15px;" class="text-danger" v-if="isAdmin"><i class="fa fa-wrench"></i> admin</span>
        <span class="text-danger" v-if="isTester"><i class="fa fa-heartbeat"></i> tester</span>
      </div>

      <p v-if="userAuthInfo?.accountCreatedDateText">account created {{userAuthInfo.accountCreatedDateText}}</p>
      <p>{{ user.viewed.length }} films viewed</p>
    </div>

  </div>


<!--  modal for updating username -->
  <section class="modal fade" id="update-username-modal" tabindex="-1" role="dialog" aria-labelledby="modalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5>update username</h5>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitUsernameChange">
            <div class="input-group">
              <input type="text" class="form-control" v-model="editUsername" aria-label="Recipient's username with two button addons" aria-describedby="button-addon1" >
              <button class="btn btn-outline-success" type="submit" data-bs-title="Accept">
                <i class="fa fa-check"></i>
              </button>
              <button class="btn btn-outline-danger" type="button" data-bs-title="Reject" @click="updateUsernameModal.hide()">
                <i class="fa fa-times"></i>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>

</template>


<script>
import axios from "axios";
import {userStore} from "@/userStore";
import {Modal} from "bootstrap";

export default {
  name: "ProfilePage",
  components: {},
  computed: {
    user() {
      return userStore.user;
    },
    isAdmin() {
      return userStore.isAdmin();
    },
    isTester() {
      return userStore.isTester();
    }

  },
  data() {
    return {
      show_modal: false,
      editUsername: userStore.user?.username ? userStore.user.username : '',
      waitingForResponse: false,
      updateUsernameModal: null,
      userAuthInfo: null,
      logoPath: `${process.env.BASE_URL}logos/`
    }
  },
  async created() {
    axios
        .get("/api/user/auth-info")
        .then((response) => {
          console.log(response.data);
          this.userAuthInfo = response.data;

          const kMonthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
          const creationDateObj = new Date(this.userAuthInfo.accountCreatedDate);
          this.userAuthInfo.accountCreatedDate = creationDateObj;
          this.userAuthInfo.accountCreatedDateText = `${kMonthNames[creationDateObj.getMonth()]} ${creationDateObj.getFullYear()}`;
          console.log(response.data);
        }, (error) => {
          console.log(error);
        });
  },
  mounted() {
    this.updateUsernameModal = new Modal('#update-username-modal', {});
    this.updateUsernameModal.hide();
  },
  methods: {

    showUpdateUsernameModal() {
      this.editUsername = this.user.username;
      this.updateUsernameModal?.show();
    },

    async submitUsernameChange() {

      this.waitingForResponse = true;
      axios.post(`/api/user/update/username/${this.editUsername}`)
          .then((response) => {
            userStore.user = response.data;
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.waitingForResponse = false;
            this.updateUsernameModal.hide();
          })
    }
  }
}
</script>


<style scoped>
.form-control {
  display: inline;
  width: 200px;
}
.edit-profile-name {

  float: right;
  width: fit-content;

  .fa {
    color: var(--bs-card-border-color);
    font-size: 20px;
    //opacity: 40%;
  }
}

.auth-info {
  text-align: right;
  font-size: small;

  p {
    margin-bottom: 0px;
  }
  .email {
    font-weight: 500;
  }
  .google-icon {
    height: 20px;
    margin-top: -2px;
  }
}

.profile-body {
  margin-top: 20px;
}

.profile-pic {
  border-radius: 50%;
  margin-top: -4px;
}

</style>