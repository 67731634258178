<template>
<p>Page not Found</p>
</template>


<script>
export default {
  name: "NotFoundPage"
}
</script>


<style scoped>

</style>