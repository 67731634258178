<template>
  <div class="container text-center">

    <div class="row">
      <div class="col-12">
        <h1>About myOscarList</h1>
      </div>
    </div>

    <div class="row about-text">
      <div class="col-12">
        <p>myOscarList is a checklist of every film ever nominated for an Academy Award.</p>
        <p>Once you create an account, you can view and update your personal checklist of films.</p>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card card-body tmdb-attribution-card">
          <img width="50" src="/logos/tmdb-logo.svg" alt="Tmdb logo" />
          <div class="tmdb-attribution-text">
            This website uses the TMDB API but is not endorsed or certified by TMDB.
          </div>
        </div>
      </div>
    </div>

  </div>
</template>


<script>
export default {
  name: "AboutPage"
}
</script>


<style scoped>

  .about-text {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .tmdb-attribution-card {
    text-size: small;
    width: 150px;
    background-color: #0d253f;
    border: 5px solid #01b4e4;
    color: #01b4e4;
    margin-left: auto;
    margin-right: auto;

    .tmdb-attribution-text {
      margin-top: 10px;
      font-size: x-small;
      font-weight: 400;
      text-align: justify;
    }

    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }

  }
</style>