<template>

  <div  class="card bg-light text-center">

    <div v-if="state === PRState.VERIFYING_LINK" class="card-body">
      <h3 v-if="!user" class="card-title text-dark">Verifying Link</h3>
      <LoadingSpinner class="text-secondary" style="margin-top: 10px;"></LoadingSpinner>
    </div>

    <div v-else-if="state === PRState.ERROR_VERIFYING_LINK" class="card-body">
      <h3 class="card-title text-dark">Invalid Link</h3>
      <p><i class="fa fa-exclamation-circle"></i> The password reset link is malformed or expired. Try creating a new one.</p>
      <router-link style="text-decoration:none;" to="/welcome">Return to Sign In Page</router-link>
    </div>

    <form v-else-if="state === PRState.SET_PASSWORD" class="card-body">
      <h3 class="card-title text-dark">Set Password</h3>
      <p>{{name}}<br><em>{{email}}</em></p>

      <div class="form-group">
        <input class="form-control" type="password" placeholder="Password" v-model="typedPassword"/>
        <input class="form-control" type="password" placeholder="Retype Password" v-model="retypedPassword"/>
      </div>

      <div class="d-grid gap-2">
        <input
            class="btn btn-outline-primary"
            :class="{disabled: !passwordValid}"
            type="submit"
            :value="`Set New Password${passwordValid? ' ✅' : ''}`"
            @click.prevent="onSetNewPassword"
        />
      </div>
    </form>

    <div v-else-if="state === PRState.SETTING_PASSWORD" class="card-body">
      <h3 class="card-title text-dark">Set Password</h3>
      <LoadingSpinner class="text-secondary" style="margin-top: 10px;"></LoadingSpinner>
    </div>

    <div v-else-if="state === PRState.ERROR_SETTING_PASSWORD" class="card-body">
      <h3 class="card-title text-dark">Problem Setting Password</h3>
      <p><i class="fa fa-exclamation-circle"></i> There was an issue resetting your password.</p>
      <router-link style="text-decoration:none;" to="/welcome">Return to Sign In Page</router-link>
    </div>

    <div v-else-if="state === PRState.SUCCESS" class="card-body">
      <h3 class="card-title text-dark">Set Password</h3>
      <p><i class="fa fa-check-circle"></i> Successfully set password</p>
      <router-link style="text-decoration:none;" to="/welcome">Return to Sign In Page</router-link>
    </div>
  </div>
</template>


<script>
import axios from "axios";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import {userStore} from "@/userStore";


export default {
  name: "SubmitEmail",
  components: {LoadingSpinner},
  props: ['userId', 'token'],
  computed: {
    user() {
      return userStore.user;
    },
    passwordValid() {
      return this.typedPassword && (this.typedPassword === this.retypedPassword);
    }
  },
  data() {
    return {
      PRState: Object.freeze({
        VERIFYING_LINK: Symbol("verifying link"),
        ERROR_VERIFYING_LINK: Symbol("error verifying link"),
        SET_PASSWORD: Symbol("set password"),
        SETTING_PASSWORD: Symbol("setting password"),
        ERROR_SETTING_PASSWORD: Symbol("error setting password"),
        SUCCESS: Symbol("success")
      }),
      state: null,
      typedPassword: '',
      retypedPassword: '',
      name: '',
      email: ''
    }
  },
  mounted() {
    if ( this.user ) {
      return this.initWithLoggedInUser();

    } else {
      return this.initWithTokenParam();
    }
  },
  methods: {
    async initWithLoggedInUser() {
      this.state = this.PRState.VERIFYING_LINK;
      axios
          .get("/api/user/auth-info")
          .then((response) => {
            console.log(response.data);
            this.name = this.user.username;
            this.email = response.data.email;
            this.state = this.PRState.SET_PASSWORD;
          })
          .catch((error) => {
                console.log(error);
          });
    },
    async initWithTokenParam() {
      this.state = this.PRState.VERIFYING_LINK;
      return axios
          .post("/api/auth/email/forgot-password/verify-token", {
            userId: this.userId,
            token: this.token
          })
          .then((response) => {
            console.log(response);
            this.name = response.data.name;
            this.email = response.data.email;
            this.state = this.PRState.SET_PASSWORD;
          })
          .catch((error) => {
            console.log(error);
            this.state = this.PRState.ERROR_VERIFYING_LINK;
          });
    },
    async onSetNewPassword() {

      this.state = this.PRState.SETTING_PASSWORD;

      this.callSetPasswordEndpoint()
          .then((response) => {
            if (response.status === 200) {
              console.log(response);
              this.state = this.PRState.SUCCESS;
            }
          }, (error) => {
            console.log(error);
            this.state = this.PRState.ERROR_SETTING_PASSWORD;
          });
    },

    async callSetPasswordEndpoint() {
      if ( this.user ) {
        return axios.post("/api/auth/email/set-password", {
              password: this.typedPassword
            });
      } else {
        return axios.post("/api/auth/email/forgot-password/set-password", {
              userId: this.userId,
              token: this.token,
              password: this.typedPassword
            });
      }
    },

  }
}
</script>


<style scoped>
.form-control {
  margin-top: 10px;
  margin-bottom: 10px;
}

.fa-exclamation-circle {
  color: darkred;
}

.fa-check-circle {
  color: green;
}
</style>