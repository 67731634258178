<template>

  <div  class="card bg-light text-center">

    <form v-if="state === ESState.COLLECTING_EMAIL" class="card-body">
      <h3 class="card-title text-dark">Reset Your Password</h3>
      <hr>

      <div class="form-group">
        <p>Enter your email address and you'll receive a link to reset your password.</p>
        <input class="form-control" type="text" placeholder="Email Address" v-model="email" />
      </div>

      <div class="d-grid gap-2">
        <input class="btn btn-outline-primary" type="submit" value="submit" @click.prevent="sendResetPasswordEmail"/>
      </div>
    </form>

    <div v-else-if="state === ESState.SENDING_EMAIL" class="card-body">
      <h3 class="card-title text-dark">Reset Your Password</h3>
      <LoadingSpinner class="text-secondary" style="margin-top: 10px;"></LoadingSpinner>
    </div>

    <div v-else-if="state === ESState.SENT_EMAIL" class="card-body">
      <h3 class="card-title text-dark">Request Submitted</h3>
      <p>Check your email for next steps.</p>
    </div>

    <div v-else-if="state === ESState.ERROR_SENDING_EMAIL" class="card-body">
      <h3 class="card-title text-dark">Error Sending Email</h3>
      <p>There was a problem sending your password reset link to {{email}}.</p>
      <router-link style="text-decoration:none;" to="/welcome">Return to Sign In Page</router-link>
    </div>

  </div>
</template>


<script>
import axios from "axios";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

export default {
  name: "SubmitEmail",
  components: {LoadingSpinner},
  data() {
    return {
      ESState: Object.freeze({
        COLLECTING_EMAIL: Symbol("collecting email"),
        SENDING_EMAIL: Symbol("sending email"),
        SENT_EMAIL: Symbol("sent email"),
        ERROR_SENDING_EMAIL: Symbol("error sending email")
      }),
      state: null,
      email: ''
    }
  },
  mounted() {
    this.state = this.ESState.COLLECTING_EMAIL;
  },
  methods: {
    async sendResetPasswordEmail() {
      this.state = this.ESState.SENDING_EMAIL;
      axios
          .post("/api/auth/email/forgot-password/send-reset-link", {
            email: this.email
          })
          .then((response) => {
            console.log(response);
            this.state = this.ESState.SENT_EMAIL;
          })
          .catch((error) => {
            console.log(error);
            this.state = this.ESState.ERROR_SENDING_EMAIL;
          });
    }
  }
}
</script>


<style scoped>
.form-control {
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>