<template>
  <button class="btn btn-sm btn-outline-primary" type="button" @click="showModal">
    <i class="fa fa-user-plus"></i> invite a friend to connect
  </button>
  <GenericModal v-if="show_code_modal" @modal-closed="show_code_modal=false">
    <template #header>
      <h6>share link to add friend</h6>
    </template>

    <div style="text-align: center">
      <QRCodeVue3 :value="link"
                  :width="250" :height="250"
                  :image="logo"
                  :imageOptions="{margin: 2, imageSize: 0.25}"
                  :dotsOptions="{type: 'extra-rounded'}"
                  :qrOptions="{ typeNumber: 0, mode: 'Byte', errorCorrectionLevel: 'H' }"
      />
      <div class="d-grid gap-2">
        <button class="btn btn-sm btn-block btn-outline-success" type="button" @click="copyLinkToClipboard">
          <span v-if="!textCopied">copy invite link<br><span style="opacity: 50%;">{{link}}</span></span>
          <span v-else>copied!</span>
        </button>
      </div>
    </div>

  </GenericModal>
</template>


<script>
import GenericModal from "@/components/modal/GenericModal.vue";
import {userStore} from "@/userStore";
import QRCodeVue3 from "qrcode-vue3";

export default {
  name: "FriendCodeLink",
  components: {QRCodeVue3, GenericModal},
  computed: {
    link() {
      return `${location.origin}/connect-with/${userStore.getFriendConnectionId()}`;
    },
    logo() {
        return process.env.BASE_URL + 'logo-64.png';
    }
  },
  data() {
    return {
      show_code_modal: false,
      textCopied: false
    }
  },
  methods: {
    showModal() {
      this.show_code_modal = true;
      this.textCopied = false;
    },
    copyLinkToClipboard() {
      navigator.clipboard.writeText( this.link );
      this.textCopied = true;
    }
  }
}
</script>


<style scoped>

</style>