<template>
  <div v-if="user" class="container">

    <div class="row">
      <div class="col-6">
        <h1>Friends</h1>
      </div>
      <div class="col-6" style="text-align: right;">
        <FriendCodeLink></FriendCodeLink>
      </div>
    </div>

<!--    alert announcing any invites that need accepting -->
    <div v-for="invite in invites_received" :key="invite._id">
      <FriendCard_InviteReceived :friend="invite" @update-friends-list="getFriendReport()"></FriendCard_InviteReceived>
    </div>

    <!--    friends -->
    <div v-for="friend in confirmed_friends" :key="friend._id">
      <FriendCard_Friend :friend="friend" @update-friends-list="getFriendReport()"></FriendCard_Friend>
    </div>

    <!--    invited users look like faded-out friends  -->
    <div v-if="invites_sent.length > 0" style="margin-top: 50px; margin-bottom: 50px; color: cornflowerblue;">
      <hr>
      <h6><i class="fa fa-hourglass-1" aria-hidden="true"></i> waiting for their confirmation...</h6>
    </div>

    <div v-for="invite in invites_sent" :key="invite._id">
      <FriendCard_InviteSent :friend="invite" @update-friends-list="getFriendReport()"></FriendCard_InviteSent>
    </div>
  </div>

</template>


<script>
import axios from "axios";
import {userStore} from "@/userStore";
import FriendCard_Friend from "@/components/friends/cards/FriendCard-Friend.vue";
import FriendCard_InviteReceived from "@/components/friends/cards/FriendCard-InviteReceived.vue";
import FriendCard_InviteSent from "@/components/friends/cards/FriendCard-InviteSent.vue";
import FriendCodeLink from "@/components/friends/FriendCodeLink.vue";

export default {
  name: "FriendsPage",
  components: {FriendCodeLink, FriendCard_Friend, FriendCard_InviteReceived, FriendCard_InviteSent },
  data() {
    return {
      userStore,
      friendReport: {
        confirmed: [],
        invites_sent: [],
        invites_received: []
      }
    }
  },
  computed: {
    user() {
      return userStore.user;
    },
    confirmed_friends() {
      return this.friendReport.confirmed;
    },
    invites_sent() {
      return this.friendReport.invites_sent;
    },
    invites_received() {
      return this.friendReport.invites_received;
    },
    publicPath() {
      return process.env.BASE_URL;
    }
  },
  async created() {
    return this.getFriendReport()
        .catch((err) => {
          console.log(err);
        });
  },
  methods : {
    async getFriendReport() {
      //console.log(`getFriendReport()`);
      return axios
          .get("/api/friend/report")
          .then((response) => {
            this.friendReport = response.data;
          })
          .catch((err) => {
            console.log(err);
          });
    },
  }
}
</script>


<style scoped>
.card {
  margin: 20px;
}

.invited-friend-card {
  opacity: 40%;
}

.friend-name {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.badge {
  //opacity: 75%;
  margin-left: 2px;
  font-size: 50%;
  vertical-align: top;
}
.remove-friend-button {
  float: right;
  width: fit-content;

  .fa {
    color: var(--bs-card-border-color);
    font-size: 20px;
  //opacity: 40%;
  }
}
.alert {
  .invite-text {
    margin-bottom: 20px;
  }
  .friend-name {
    font-weight: 500;
    margin-bottom: 0px;
  }

  button {
    margin-top: 5px;
    margin-right: 5px;
    margin-left: 5px;
  }
}
</style>