<template>
  <div class="container-fluid">
    <div class = "row">
      <div class="col-xs-9" style="padding-right: 0px;">
        <div v-for="film in films" :key="film._id">
          <FilmWithCheckbox
              :film_id=film._id
              :film_name=film.name
              :film_year="show_years? film.year : null"
              :original_language="film.original_language"
              :num_noms=film.numNominations() :num_wins=film.numWins()
          />
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import FilmWithCheckbox from "@/components/FilmWithCheckbox.vue";

export default {
  name: "FilmCheckist",
  components: {FilmWithCheckbox},
  props: ['films', 'show_years']
}
</script>


<style scoped>

</style>