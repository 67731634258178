<template>
  <div class="card card-body">
    <FriendCard_Stranger class="send-invite-card" :friend="friend">

    </FriendCard_Stranger>

    <div class="text-center">
      <div class="invite-text">
        <p>send connection invite to</p>
        <p style="" class="friend-name">{{friend.username}}</p>
      </div>
      <button class="btn btn-sm btn-primary me-sm-2" type="button" :disabled="buttonDisabled" @click="sendConnectionRequest()">
        <i class="fa fa-user-plus"></i> send
      </button>
    </div>
  </div>
</template>


<script>
import axios from "axios";
import {userStore} from "@/userStore";
import FriendCard_Stranger from "@/components/friends/cards/FriendCard-Stranger.vue";

export default {
  name: 'FriendCard_SendInvite',
  components: {FriendCard_Stranger},
  props: ['friend'],
  emits: ['update-friends-list'],
  computed: {
    user() {
      return userStore.user;
    },
    buttonDisabled() {
      return ( this.user._id === this.friend._id );
    }
  },
  methods: {
    async sendConnectionRequest() {
      if ( !this.friend?._id ) {
        return;
      }
      axios.get(`/api/friend/invite/${this.friend._id}`)
          .then((response) => {
            userStore.user = response.data;
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.goToFriendsPage();
          })
      ;
    },

    goToFriendsPage() {
      this.$router.push({name: 'FriendsPage'});
    }
  }

}
</script>


<style scoped>

  .invite-text {
    text-align: center;
    margin-bottom: 10px;

    p {
      margin-bottom: 0px;

      &.friend-name {
        font-weight: 500;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }


</style>