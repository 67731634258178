<template>
  <a @click="show_modal=true">
    <slot></slot>
  </a>
  <FilmOrCategoryModal v-if="show_modal" :category_name="category" :ceremony="ceremony" @modal-closed="show_modal=false"></FilmOrCategoryModal>
</template>


<script>
import { userStore } from "@/userStore";
import FilmOrCategoryModal from "@/components/modal/FilmOrCategoryModal.vue";

export default {
  name: "CategoryLink",
  components: {
    FilmOrCategoryModal
  },
  props: ['category', 'ceremony'],
  data() {
    return {
      show_modal: false,
      userStore
    }
  },
  created() {

  },
  methods: {
  }
}
</script>

<style scoped>

  a {
    text-decoration: inherit;
    color: inherit;
    cursor: pointer;
  }
  a:hover {
    color: var(--bs-link-hover-color);
  }
</style>