<template>
  <section class="ceremony-section container-fluid">

<!--  ceremony nav bar-->
    <ul ref="ceremonynavbar" class="nav fixed-top bg-light">
      <div v-for="(idx) in numCeremonies" :key="idx">
        <li class="nav-item">
          <a @click="setCeremony((numCeremonies+1)-idx)" :class="{ active: ((numCeremonies+1)-idx === ceremony) }" class="nav-link" aria-current="page" href="#">
            <span class="nav-ceremony-num">{{getCeremonyOrdinalText((numCeremonies+1)-idx)}}</span>
            <br>
            <span class="nav-year">{{getYearOfCeremony((numCeremonies+1)-idx)}}</span>
          </a>
        </li>
      </div>
    </ul>

<!--  films-->

    <div class="row">
      <div class="ceremony col-12">
        <LoadingSpinner v-if="filmlist.length === 0" class="text-secondary" loading_text="loading" style="margin-top: 100px;"></LoadingSpinner>

        <div v-else>
          <div v-if="showCeremonyOverlay" class="ceremony-header text-secondary fixed-top col-12">
            {{ceremonyOrdinalText}} Ceremony • {{ceremonyDateText}}
          </div>

          <div class="checklist">
            <div class="year-header text-secondary">
              <div>{{ceremonyOrdinalText}} Ceremony • {{ceremonyDateText}}</div>
              <div>Honoring achievements in</div>
              <div>{{filmlist.length}} films from {{ceremonyYearsHonoredText(ceremony)}}</div>
            </div>


            <div class="container-fluid">
                <div v-for="(film, index) in filmlist" :key="film._id">
                  <hr v-if="index > 0" class="film-separator">
                  <ChecklistFilm :film=film></ChecklistFilm>
                </div>
            </div>
          </div>
        </div>
      </div>

    </div>

  </section>

</template>


<script>

import axios from "axios";
//import FilmChecklist from "@/components/FilmChecklist.vue";
import {Film} from "@/film";
//import FilmWithCheckbox from "@/components/FilmWithCheckbox.vue";
import ChecklistFilm from "@/components/ChecklistFilm.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import {nextTick} from "vue";
//import AchievementGrapic from "@/components/AchievementGrapic.vue";

export default {
  name: "ChecklistPage",
  components: {LoadingSpinner, ChecklistFilm},
  props: ['init_ceremony'],
  data() {
    return {
      ceremony: null,
      numCeremonies: 0,
      filmlist: [],
      categoryMap: new Map(),
      ceremonyOverview: null,
      showCeremonyOverlay: false
    }
  },
  async mounted() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant',
    });
    window.addEventListener("scroll", this.onScroll, true);

    axios.get('/api/ceremony/overview')

        .then((result) => {
          this.ceremonyOverview = result.data;

          // put most recent ceremonies first
          this.ceremonyOverview.sort((cer1, cer2) => {
            return (cer1.ceremony < cer2.ceremony) ? 1 : (cer1.ceremony > cer2.ceremony) ? -1 : 0;
          });

          this.numCeremonies = this.ceremonyOverview.length;
          
          // give it a tick to render before trying to scroll
          return nextTick();
        })

        .then(() => {
          const initial_ceremony = this.init_ceremony? Number(this.init_ceremony) : this.numCeremonies;

          const init_scroll_percent = 1.0 - (initial_ceremony / this.numCeremonies);
          const init_scroll_pixels = (this.$refs.ceremonynavbar.scrollWidth * init_scroll_percent) - 20;

          this.$refs.ceremonynavbar.scrollTo({
            top: 0,
            left: init_scroll_pixels,
            behavior: 'instant',
          });
          return this.setCeremony( ((initial_ceremony > 0) && (initial_ceremony < this.numCeremonies))? initial_ceremony : this.numCeremonies );
        })

        .catch((err) => {
          console.log(err);
        });
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.onScroll, true)
  },
  computed: {
    ceremonyOrdinalText() {
      return Film.getCeremonyOrdinalText(this.ceremony);
    },
    ceremonyDateText() {
      const kMonthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      const date = Film.getDateOfCeremony(this.ceremony);
      return `${kMonthNames[date.getUTCMonth()]} ${date.getUTCDate()} ${date.getUTCFullYear()}`;
    }
  },
  methods: {
    async setCeremony(_ceremony) {
      //console.log(`setCeremony(${_ceremony})`)

      if ( (_ceremony > 0) && (_ceremony <= this.numCeremonies) && (_ceremony != this.ceremony) ) {
        this.ceremony = _ceremony;
        this.filmlist = [];

        axios.get(`/api/ceremony/${_ceremony}/films`)
            .then((response) => {
              this.filmlist = response.data.map(f => new Film(f));
              Film.sortFilmsByPrioirity(this.filmlist);
              this.populateCategoryMap();
            })

            .catch((err) => {
              console.log(err);
            });
      }
    },

    populateCategoryMap() {

      // create a map where
      //  key =>  name of nomination category
      //  value => array of { film_id, won } objects
      this.categoryMap = new Map();
      this.filmlist.forEach((film) => {
        film.nominations.forEach((nom) => {
          const newValue = {
            film_id: film._id,
            won: nom.won
          };
          let films = this.categoryMap.has(nom.category.name)? this.categoryMap.get(nom.category.name) : [];
          if ( newValue.won ) {
            films.unshift(newValue);
          } else {
            films.push(newValue);
          }
          this.categoryMap.set(nom.category.name, films);
        });
      });
      //console.log(this.categoryMap);
    },

    ceremonyYearsHonoredText(_ceremony) {
      return Film.getCeremonyYearText(_ceremony);
    },

    getCeremonyOrdinalText(_ceremony) {
      return Film.getCeremonyOrdinalText(_ceremony);
    },

    getYearOfCeremony(_ceremony) {
      const date = Film.getDateOfCeremony(_ceremony);
      return date.getUTCFullYear();
    },

    onScroll(/*e*/) {
      this.showCeremonyOverlay = (window.top.scrollY > 60);
    }
  }
}
</script>


<style scoped>

.ceremony-section {
  background-color: FloralWhite;
  min-height: 100vh;
}

ul {
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 1.5rem;
  padding-left: 0;
  margin-block: 0;
  overflow-x: auto;
  list-style: none;
  text-align: center;
  white-space: nowrap;
}

.nav {

  /*
  opacity: 80%;
   */

  margin-top: 60px;
  padding-top: 20px;
  z-index: 2;
  overflow-y: hidden;
  scroll-behavior: smooth;

  .nav-item {
    line-height: 15px;

    .nav-ceremony-num {
    }
    .nav-year {
      font-size: small;
    }
    .active {
      font-weight: 700;
    }
    a {
      color: inherit;
    }
  }
}

.ceremony {
  font-family: "Courier New";

  padding-top: 20px;
  padding-bottom: 50px;
  width: fit-content;
  margin-right: auto;
  margin-left: auto;
}

.ceremony-header {

  margin-top: 150px;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;

  font-weight: bold;
  z-index: 2;

  /* color | offset-x | offset-y | blur-radius */
  //text-shadow: floralwhite 1px 1px 10px;

  /* box-shadow: x y blur spread color */
  box-shadow: 0px 0px 20px 1px lightgrey;

  border-radius: 10px;
  border-color: lightgrey;
  border-style: solid;
  border-width: thin;
  padding: 5px 15px 5px 15px;

  background-image: url(../../public/backgrounds/paper-texture.jpg);
  animation: fadeIn 1s;
}


.checklist {
  max-width: 450px;
  margin: 90px 20px 50px 20px;
  padding-top: 20px;
  padding-bottom: 30px;
  border-radius: 0;
  z-index: 1;

  /*
  background-image: url(../../public/backgrounds/seamless_paper_texture.webp);
   */
  background-image: url(../../public/backgrounds/paper-texture.jpg);
  background-repeat:repeat;

  /* box-shadow: x y blur spread color */
  box-shadow: 3px 3px 5px 1px rgba(0, 0, 0, .1);

  .year-header {

    top: 140px;
    background-image: url(../../public/backgrounds/paper-texture.jpg);

    text-align: center;
    padding-top: 20px;
    margin-bottom: 30px;

    div:nth-child(1) {
      font-weight: bold;
    }
    div:nth-child(2) {
      font-size: smaller;
    }
    div:nth-child(3) {
      font-size: smaller;
    }
  }

  .film-separator {
    margin: 0px 20px 0px 20px;
    opacity: 10%;
  }
}

@keyframes fadeIn {
  0% {opacity: 0%;}
  100% {opacity: 100%;}
}
</style>