<template>
  <div class="container">
    <div class="row">
      <div class="offset-sm-2 col-sm-8 offset-md-2 col-md-8 offset-lg-3 col-lg-6 offset-xl-4 col-xl-4">

        <ResetPasswordCard v-if="passwordResetToken || user" :userId="userId" :token="passwordResetToken">
        </ResetPasswordCard>

        <EmailSubmitCard v-else>
        </EmailSubmitCard>

      </div>
    </div>
  </div>
</template>


<script>
import ResetPasswordCard from "@/components/auth/reset_password/ResetPasswordCard.vue";
import EmailSubmitCard from "@/components/auth/reset_password/EmailSubmitCard.vue";
import {userStore} from "@/userStore";

export default {
  name: "ResetPasswordPage",
  components: { EmailSubmitCard, ResetPasswordCard },
  props: ['token', 'id'],
  computed: {
    user() {
      return userStore?.user;
    },
  },

  data() {
    return {
      email: "",
      userId: this.$route.query.id? this.$route.query.id : null,
      passwordResetToken: this.$route.query.token? this.$route.query.token : null,
      sent: false
    }
  },
    mounted() {
    console.log(`id:${this.userId}  token:${this.passwordResetToken}`);
  }
}
</script>


<style scoped>
.container {
  padding-top: 50px;
}
</style>
