<template>
  <div class="card friend-card">
    <div class="card-body">
      <div class="d-flex">
        <div class="p-2 w-100">
          <p class="friend-name">
            <img v-if="friend.picture_url" :src="friend.picture_url" height="20" class="profile-pic" referrerPolicy="no-referrer">
            <i v-else class="fa fa-user-circle"></i> {{friend.username}}
          </p>
        </div>
        <div class="p-2 flex-shrink-1">
          <a v-if="cancel_button_text" class="remove-friend-button" href="#" :title="cancel_button_text" @click.prevent="$emit('cancel-button-clicked');">
            <i class="fa fa-user-times"></i>
          </a>
        </div>
      </div>
    </div>

    <slot></slot>

  </div>
</template>


<script>

export default {
  name: 'FriendCard',
  props: ['friend', 'cancel_button_text'],
  emits: ['cancel-button-clicked'],
  computed: {
  },
  methods: {
  }
}
</script>


<style scoped>

.friend-card {
  background-color: aliceblue;
  border-color: cornflowerblue;
  color: cornflowerblue;
  margin: 20px;
}

.friend-name {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.remove-friend-button {
  float: right;
  width: fit-content;

  .fa {
    color: var(--bs-card-border-color);
    font-size: 20px;
  //opacity: 40%;
  }
}
.alert {
  .invite-text {
    margin-bottom: 20px;
  }
  .friend-name {
    font-weight: 500;
    margin-bottom: 0px;
  }

  button {
    margin-top: 5px;
    margin-right: 5px;
    margin-left: 5px;
  }
}
.profile-pic {
  border-radius: 50%;
  margin-top: -4px;
}
</style>