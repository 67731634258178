<template>
<!--  <div class="d-grid gap-2 col-md-6 col-xl-8 mx-auto">-->
  <div class="d-grid gap-2">
      <a class="btn btn-primary btn-block" href="/api/auth/login/google">
          <img class="google-icon" :src="`${logoPath}google-logo.svg`"/>
          <span v-if="createAccount" class="google-text">Sign Up with Google</span>
          <span v-else class="google-text">Sign In with Google</span>
      </a>
  </div>
</template>


<script>
/* eslint-disable */
import { defineComponent } from 'vue'
import axios from 'axios'
import {userStore} from "@/userStore";

axios.defaults.withCredentials = true
export default defineComponent({
  name: "Google",
  props: ['createAccount'],
  emits: ['user-updated'],
  computed: {
    user() {
      return userStore.user;
    }
  },
  data() {
    return {
      logoPath: `${process.env.BASE_URL}logos/`
    }
  },
  methods: {
  }
});
</script>


<style scoped>
.row {
  margin-top: 50px;
}

.google-icon {
  padding-right: 10px;
  height: 20px;
  margin-top: -2px;
}
</style>