<template>
  <div class="container">

    <h1><i class="fa fa-user"></i> users</h1>
    <div v-if="users" class="userbase-info">
      <div>{{users.length}} users</div>
    </div>
    <hr>

    <div v-for="(user) in users" :key="user._id" class="card text-bg-light">
      <div>

        <div class="card-header">
          <img v-if="user.picture_url" :src="user.picture_url" height="20" class="profile-pic" referrerPolicy="no-referrer">
          <i v-else class="fa fa-user-circle"></i>
          {{user.username}}
        </div>

        <div class="card-body">
          <div>account created {{dateDisplayString(user.accountCreatedDate)}}</div>
          <div>
            <span style="margin-right:15px;" class="text-danger" v-if="user.isAdmin"><i class="fa fa-wrench"></i> admin</span>
            <span class="text-danger" v-if="user.isTester"><i class="fa fa-heartbeat"></i> tester</span>
          </div>
          <div>
            <span v-if="user.isGoogleAuthed"><img class="auth-icon" :src="`${logoPath}google-logo.svg`"/></span>
            <span v-if="user.isAppleAuthed"><img class="auth-icon" :src="`${logoPath}apple-logo.svg`"/></span>
            <span v-if="user.isEmailAuthed"><i class="fa fa-envelope-o"></i></span>
          </div>
          <div>
            {{user.viewed.length}} films marked viewed
          </div>
        </div>

      </div>

    </div>
  </div>
</template>


<script>
import axios from "axios";

export default {
  name: "AdminUsersPage",
  data() {
    return {
      waitingForResponse: false,
      users: null,
      logoPath: `${process.env.BASE_URL}logos/`
    }
  },
  created() {
    this.waitingForResponse = true;

    // get the schedule for the given provider
    axios.get(`/api/admin/users`)
        .then((response) => {
          this.users = response.data;
        })
        .catch((err) => {
          console.error(`error calling /api/admin/users : ${err}`)
        })
        .finally(() => {
          this.waitingForResponse = false;
        });
  },
  methods: {
    dateDisplayString(_date) {
      const dateObj = ( _date instanceof Date )? _date : new Date(_date);
      return dateObj.toDateString();
    }
  }
}
</script>


<style scoped>

.userbase-info {
  margin-left: 50px;
  margin-right: 50px;
}

.card {
  margin-top: 50px;
  margin-botton: 50px;
}
.profile-pic {
  border-radius: 50%;
  margin-top: -4px;
}

.auth-icon {
  height: 20px;
  margin-top: -2px;
}



</style>