<template>
  <div class="welcome">
    <SignIn @user-updated="userUpdated()"></SignIn>
  </div>
</template>


<script>
  import SignIn from "@/components/auth/SignIn.vue";

  export default {
    name: "WelcomePage",
    components: {
      SignIn
    },
    methods: {
      userUpdated() {
        this.$router.push({name: 'DashboardPage'});
      }
    }
  }

</script>


<style scoped>
.welcome {
  padding-top: 40px;
}
</style>